import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import AddEditTermButton from '../../../components/admin/term/AddEditTermButton'
import TermList from '../../../components/admin/term/TermList'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function TermPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('term.list.main')}
                  breadcrumbs={{
                      heading: t('term.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('term.list.main'), href: PATH_DASHBOARD.admin.term}
                      ],
                      action: (
                          <AddEditTermButton/>
                      )
                  }}>
            <TermList/>
        </PageRoot>
    )
}

