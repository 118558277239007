import {TextFieldElement} from 'react-hook-form-mui'

import {useTranslation} from 'react-i18next'
import {
    CountryRegionQuery,
    LocationQuery,
    UpsertLocationMutationVariables,
    useGenericMutation,
    useMutationResult,
    ZoneListQuery
} from '@feg/ui'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {getCountryRegionNested} from '../countryRegion/countryRegionHelper'
import {FormErrorMessage} from "@feg/ui/src/common/form/FormErrorMessage";
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import {
    useCountryRegionQuery,
    useUpsertLocationMutation,
    useZoneListQuery
} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const schema = {
    name: '',
    zone_id: '',
    country_region_id: ''
}

type Schema = typeof schema

type LocationProps = {
    location?: LocationQuery['location'][number]
}

export default function LocationForm({location}: LocationProps) {
    const {t} = useTranslation()
    const {isPending, data, error, mutateAsync} = useUpsertLocationMutation()
    const {
        control,
        onSubmit
    } = useGenericMutation<Schema, UpsertLocationMutationVariables, typeof mutateAsync>({
        id: location?.id,
        defaultValues: {
            name: location?.name,
            zone_id: location?.zone_id,
            country_region_id: location?.country_region_id
        },
        mutateAsync,
        gqlSchemaName: 'insert_location_one',
        prepareFormData: data => {
            const splittedNames = data.name.split(',').map(k => k.trim())
            return {
                objects: splittedNames.map(name => ({
                    id: location?.id,
                    name,
                    zone_id: data.zone_id,
                    country_region_id: data.country_region_id
                }))
            }
        }
    })
    useMutationResult({data, error})

    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <FormErrorMessage control={control}/>

                <TextFieldElement name={'name'}
                                  label={t('location.form.name')}
                                  control={control}
                                  {...!location?.id && {
                                      multiline: true,
                                      minRows: 3,
                                      helperText: t('countryRegion.form.nameHelper')
                                  }} />
                <AutocompleteFetchQuery
                    name={'zone_id'}
                    control={control}
                    label={t('location.form.zone')}
                    asyncQuery={useZoneListQuery.fetcher()}
                    gqlSchema={'zone'}
                    matchId={true}
                    autocompleteProps={{
                        getOptionLabel: (option: ZoneListQuery['zone'][number] | string) => {
                            if (typeof option === 'string') return option
                            if (!option) return ''
                            return `${option.name} (${option.country?.en})`
                        }
                    }}
                />
                <AutocompleteFetchQuery
                    name={'country_region_id'}
                    label={t('location.form.region')}
                    asyncQuery={useCountryRegionQuery.fetcher({
                        where: {
                            _not: {
                                country_regions: {}
                            }
                        }
                    })}
                    matchId={true}
                    gqlSchema={'country_region'}
                    autocompleteProps={{
                        getOptionLabel: (option: CountryRegionQuery['country_region'][number] | string) => {
                            if (typeof option === 'string') {
                                return option
                            }
                            if (!option) return ''
                            const nested = getCountryRegionNested(option)
                            return `${option.name} // ${nested} (${option.country?.en})`
                        }
                    }}
                    control={control}/>
                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
