import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import MeasureForm from './MeasureForm'
import { MeasureListQuery } from '@feg/ui'
import { memo } from 'react'

type AddEditMeasureButtonProps = {
  measureUnit?: MeasureListQuery['measure_unit'][number]
}

function AddEditMeasureButton({ measureUnit }: AddEditMeasureButtonProps) {
  const { t } = useTranslation()
  console.log('render add edit button measure')
  return (
    <SidebarDrawer
      addButton={!measureUnit ? t('measure.list.add') : undefined}
      editButton={measureUnit ? t('measure.list.edit') : undefined}
      headline={measureUnit ? t('measure.list.edit') : t('measure.list.add')}
    >
      <MeasureForm measureUnit={measureUnit} />
    </SidebarDrawer>
  )
}

export default memo(AddEditMeasureButton)
