import {AutocompleteProps, Skeleton, TextFieldProps} from '@mui/material'
import {ControllerProps, FieldValues} from 'react-hook-form'
import {AutocompleteElement} from 'react-hook-form-mui'
import {useQuery as useTanstackQuery, UseQueryOptions} from '@tanstack/react-query'


type RhfProps<TFieldValues extends FieldValues> = Pick<
    ControllerProps<TFieldValues>,
    'control' | 'rules' | 'name'
>

type KeysFromPromise<T extends Promise<any>> = T extends Promise<infer U> ? U extends object ? keyof U : never : never;

type AutocompleteFetchQueryProps<T extends () => Promise<any>, TFieldValues extends FieldValues = FieldValues> =
    RhfProps<TFieldValues> &
    TextFieldProps
    & {
    asyncQuery: T;
    gqlSchema: KeysFromPromise<ReturnType<T>>;
    matchId?: boolean;
    queryKey?: UseQueryOptions['queryKey']
    autocompleteProps?: Pick<AutocompleteProps<any, any, any, any>, 'getOptionLabel' | 'multiple' | 'disabled'>; // Update as needed
};

export function AutocompleteFetchQuery<T extends () => Promise<any>, TFieldValues extends FieldValues = FieldValues>(
    {
        asyncQuery,
        name,
        gqlSchema,
        rules,
        control,
        matchId,
        queryKey,
        autocompleteProps,
        ...textFieldProps
    }: AutocompleteFetchQueryProps<T, TFieldValues>
) {
    const {data, isLoading, error} = useTanstackQuery({
            queryKey: queryKey ?? [name],
            queryFn: asyncQuery
        }
    )
    if (data) {
        const keys: KeysFromPromise<ReturnType<T>> = Object.keys(data as any)[0] as KeysFromPromise<ReturnType<T>>;
    }

    let items = data?.[gqlSchema] || []

    if (error) {
        console.error(error)
    }
    if (isLoading) {
        return <Skeleton/>
    }
    return (
        <AutocompleteElement name={name}
                             multiple={autocompleteProps?.multiple}
                             control={control}
                             rules={rules as any}
                             options={items}
                             loading={isLoading}
                             textFieldProps={textFieldProps}
                             matchId={matchId}
                             autocompleteProps={autocompleteProps}/>
    )
}

