import {useTranslation} from 'react-i18next'
import {
    InsertInterviewsMutationVariables,
    LocationQuery,
    Project_Interview_Insert_Input,
    useGenericMutation,
    useMutationResult
} from '@feg/ui'
import WealthGroupCheckboxGroup from '../../project/partials/WealthGroupCheckboxGroup'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {FormStack} from '@feg/ui/src/common/form/FormStack'
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import {useInsertInterviewsMutation, useLocationQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const schema = {
    location_ids: [],
    wealthGroups: []
}

type Schema = typeof schema

type AddInterviewsFormProps = {
    projectId: string
}

export default function AddInterviewsForm({projectId}: AddInterviewsFormProps) {
    const {t} = useTranslation()
    const {error, data, isPending, mutateAsync} = useInsertInterviewsMutation()

    const {
        control,
        onSubmit,
        setValue
    } = useGenericMutation<Schema, InsertInterviewsMutationVariables, typeof mutateAsync>({
        defaultValues: {
            location_ids: []
        },
        mutateAsync,
        gqlSchemaName: 'insert_project_interview',
        prepareFormData: (formData) => {
            let interviewData: Project_Interview_Insert_Input[] = []
            if (formData.location_ids?.length) {
                formData.location_ids.forEach(locationId => {
                    formData.wealthGroups?.forEach(wealthGroupId => {
                        interviewData.push({
                            project_id: projectId,
                            wealth_group_id: wealthGroupId,
                            location_id: locationId
                        })
                    })
                })
            }
            return {
                objects: interviewData
            }
        }
    })
    useMutationResult({error, data})

    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <WealthGroupCheckboxGroup control={control} name={'wealthGroups'} setValue={setValue}/>
                <AutocompleteFetchQuery
                    name={'location_ids'}
                    control={control}
                    asyncQuery={useLocationQuery.fetcher()}
                    matchId={true}
                    gqlSchema={'location'}
                    autocompleteProps={{
                        multiple: true,
                        getOptionLabel: (option: LocationQuery['location'][number] | string) => {
                            if (typeof option === 'string') {
                                return option
                            }
                            if (!option) {
                                return ''
                            }
                            return `${option.name} (${option.country_region?.name} - ${option.country_region?.country?.en})`
                        }
                    }}
                />

                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
