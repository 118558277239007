import {Status_Enum} from "../lib";
import {CheckCircleOutlined, CloudOff, CloudQueue, Lock} from "@mui/icons-material";

type InterviewStatusIconProps = {
    status?: Status_Enum | null
    is_done?: boolean | null
    hasUploadId?: boolean | null
}

export function InterviewStatusIcon({status, hasUploadId, is_done}: InterviewStatusIconProps) {
    if (status) {
        return <Lock/>
    } else if (is_done) {
        return <CheckCircleOutlined/>
    } else if (hasUploadId) {
        return <CloudQueue/>
    }
    return (
        <CloudOff/>
    )
}