import { useTranslation } from 'react-i18next'
import SidebarDrawer from '../generic/drawers/SidebarDrawer'
import AddButton from '../generic/buttons/AddButton'
import AddProjectForm from './AddProjectForm'

export default function AddProjectButton() {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      closeOnSubmit={true}
      ToggleElement={<AddButton>{t('project.list.add')}</AddButton>}
      headline={t('project.list.main')}>
      <AddProjectForm />
    </SidebarDrawer>
  )
}
