import {getProjectMonths, Month_Enum} from "../lib";
import {useTranslation} from "react-i18next";
import {useInterviewContext} from "../tss/InterviewProvider";

export const MONTHS = [Month_Enum.January, Month_Enum.February, Month_Enum.March, Month_Enum.April, Month_Enum.May, Month_Enum.June, Month_Enum.July, Month_Enum.August, Month_Enum.September, Month_Enum.October, Month_Enum.November, Month_Enum.December]
export const getMonthIndex = (month: Month_Enum) => MONTHS.indexOf(month)
export const getMonthsOnDays = (startMonth?: Month_Enum, days?: number, total?: number) => {
    if (!(startMonth && days && total)) {
        return null
    }
    const numberMonth = Math.round(Number(days) / 30)
    const eachTotal = Number(total) / numberMonth
    const startIndex = getMonthIndex(startMonth)
    const requestedMonths: { month: Month_Enum, value: number }[] = []
    for (let start = 0; start < numberMonth; start++) {
        requestedMonths.push({
            month: MONTHS[(startIndex + start) % 12],
            value: eachTotal
        })
    }
    let endMonth = requestedMonths[requestedMonths.length - 1]?.month;
    return {
        months: requestedMonths,
        endMonth: endMonth,
        nextMonth: MONTHS[(getMonthIndex(endMonth) + 1) % 12]
    }
}

export const useMonthOptions = (ns?: string) => {
    const ctx = useInterviewContext()
    const getter = ctx?.id && getProjectMonths(ctx.id)
    const monthsOpts = getter || MONTHS
    const {t} = useTranslation()
    return monthsOpts.map(k => {
        return {
            id: k,
            label: t(`month.${k}.short`, {ns})
        }
    });
}