import {TextFieldElement} from 'react-hook-form-mui'
import {
    UpsertUserProfileMutationVariables,
    useGenericMutation,
    useMutationResult,
    Users_Constraint,
    Users_Update_Column
} from '@feg/ui'
import {UserOfList} from './usersTypings'
import {useTranslation} from 'react-i18next'
import SubmitButton from '../generic/buttons/SubmitButton'
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {FormErrorMessage} from "@feg/ui/src/common/form/FormErrorMessage";
import {useUpsertUserProfileMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const schema = {
    first_name: '',
    last_name: '',
    email: ''
}

type Schema = typeof schema

type UserFormProps = {
    user?: UserOfList
}

export default function UserForm({user}: UserFormProps) {
    const {t} = useTranslation()
    const {isPending, data, error, mutateAsync} = useUpsertUserProfileMutation()
    const {control, onSubmit} = useGenericMutation<Schema, UpsertUserProfileMutationVariables, typeof mutateAsync>({
        id: user?.user_profile?.id,
        defaultValues: {
            last_name: user?.user_profile?.last_name || '',
            first_name: user?.user_profile?.first_name || '',
            email: user?.email || ''
        },
        mutateAsync,
        gqlSchemaName: 'insert_user_profile_one',
        prepareFormData: (formData, id) => {
            return {
                object: {
                    id,
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    user: {
                        data: {
                            ...(user?.id && {
                                id: user.id
                            }),
                            email: formData.email,
                            displayName: `${formData.first_name} ${formData.last_name}`,
                            locale: 'en',
                            ...(!user && {
                                defaultRole: 'user',
                                roles: {
                                    data: [{
                                        role: 'me'
                                    }, {
                                        role: 'user'
                                    }]
                                }
                            })
                        },
                        on_conflict: {
                            constraint: Users_Constraint.UsersPkey,
                            update_columns: [Users_Update_Column.Email, Users_Update_Column.DisplayName]
                        }
                    }
                }
            }
        }
    })
    useMutationResult({error, data})
    return (
        <form onSubmit={onSubmit} noValidate>
            <FormStack>
                <FormErrorMessage control={control}/>
                <TextFieldElement name={'first_name'} label={t('user.form.firstName')} control={control}/>
                <TextFieldElement name={'last_name'} label={t('user.form.lastName')} control={control}/>
                <TextFieldElement name={'email'} label={t('user.form.email')} control={control}/>
                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
