// @mui
import {Divider, Stack, Typography} from '@mui/material'
// hooks
// utils
// config
//
import Iconify from '../Iconify'
import Scrollbar from '../Scrollbar'
import SettingMode from './SettingMode'
import SettingStretch from './SettingStretch'
import SettingFullscreen from './SettingFullscreen'
import {useTranslation} from 'react-i18next'
import LanguageSwitch from '../../../common/settings/LanguageSwitch'
import IconButtonAnimate from "../animate/IconButtonAnimate";
import {useSettingsState} from "../../contexts/SettingsContext";

// ----------------------------------------------------------------------

export default function Settings() {
    const {onResetSetting} = useSettingsState()
    const {t} = useTranslation()
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{py: 2, pr: 1, pl: 2.5}}
            >
                <Typography variant="subtitle1">{t('user.settings.main')}</Typography>
                <div>
                    <IconButtonAnimate onClick={onResetSetting}>
                        <Iconify icon={'ic:round-refresh'} width={20} height={20}/>
                    </IconButtonAnimate>
                </div>
            </Stack>

            <Divider sx={{borderStyle: 'dashed'}}/>

            <Scrollbar sx={{flexGrow: 1}}>
                <Stack spacing={4} sx={{p: 3}}>
                    <Stack spacing={1.5}>
                        <Typography variant="subtitle2">
                            {t('user.settings.language')}
                        </Typography>
                        <LanguageSwitch/>
                    </Stack>
                </Stack>
                <Stack spacing={4} sx={{p: 3}}>
                    <Stack spacing={1.5}>
                        <Typography variant="subtitle2">
                            {t('user.settings.mode')}
                        </Typography>
                        <SettingMode/>
                    </Stack>


                    <Stack spacing={1.5}>
                        <Typography variant="subtitle2">
                            {t('user.settings.stretch')}
                        </Typography>
                        <SettingStretch/>
                    </Stack>

                    <SettingFullscreen/>
                </Stack>
            </Scrollbar>
        </div>
    )
}
