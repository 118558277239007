import {ReactNode, useMemo} from 'react'
import {CssBaseline} from '@mui/material'
import {createTheme, Theme, ThemeOptions, ThemeProvider} from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import shadows, {customShadows} from './shadows'
import {useSettingsState} from "../contexts/SettingsContext";

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function AppThemeProvider({children}: Props) {
    const {themeMode, themeDirection} = useSettingsState()
    const isLight = themeMode === 'light'

    const currentTheme: Theme = useMemo(
        () => {
            const themeOptions: ThemeOptions = {
                palette: isLight ? palette.light : palette.dark,
                typography,
                breakpoints,
                shape: {borderRadius: 8},
                direction: themeDirection,
                shadows: isLight ? shadows.light : shadows.dark,
                customShadows: isLight ? customShadows.light : customShadows.dark,
            }
            const theme = createTheme(themeOptions)
            // todo component overrides
            // theme.components = componentsOverride(theme)
            return theme
        },
        [isLight, themeDirection]
    )

    // might need to split this up because of inject first for storybook
    return (
        <ThemeProvider theme={currentTheme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}
