import {useForm} from 'react-hook-form'
import {Alert, Box, BoxProps, Stack, Typography} from '@mui/material'
import {TextFieldElement} from 'react-hook-form-mui'
import {LoadingButton} from '@mui/lab'
import {useTranslation} from 'react-i18next'
import {useResetPassword} from '@nhost/react'
import {m} from 'framer-motion'
import {varBounce} from "../../theme/components/animate/variants";
import {ForwardRefExoticComponent} from "react";
import MuiLink from "@mui/material/Link";

const schema = {
    email: ''
}
type User = typeof schema

type PasswordForgetFormProps = {
    onSuccess?: () => Promise<void>
    containerProps?: BoxProps
    LinkComponent?: ForwardRefExoticComponent<any>
    linkHrefTo?: boolean
}

export function PasswordForgetForm({onSuccess, containerProps, LinkComponent, linkHrefTo}: PasswordForgetFormProps) {
    const {t} = useTranslation()
    const {resetPassword, error, isLoading, isSent} = useResetPassword()
    const {control, handleSubmit} = useForm<User>()
    const onSubmit = handleSubmit(async (data) => {
        await resetPassword(data.email, {
            redirectTo: `${window.location.origin}/auth/change-password`
        })
        if (typeof onSuccess === 'function') {
            await onSuccess()
        }
    })

    return (
        <Box {...containerProps}>
            <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                    {t('auth.headlineForgotPassword')}
                </Typography>
            </m.div>
            {error && (
                <m.div variants={varBounce().in}>
                    <Alert severity={'error'}>
                        {error?.message}
                    </Alert>
                </m.div>
            )}
            {isSent && (
                <m.div variants={varBounce().in}>
                    <Alert severity={'success'}>
                        {t('auth.passwordResetMessage')}
                    </Alert>
                </m.div>
            )}
            {!isSent && (
                <form onSubmit={onSubmit} noValidate>
                    <Stack direction={'column'} spacing={2}>
                        <TextFieldElement name={'email'} label={t('auth.email')} control={control}/>
                        <LoadingButton type={'submit'} loading={isLoading}>{t('submit')}</LoadingButton>
                        <MuiLink
                            {...LinkComponent && {
                                component: LinkComponent
                            }}
                            {...linkHrefTo ? {
                                to: '/auth/login'
                            } : {
                                href: '/auth/login'
                            }}
                        >
                            {t('auth.backToLogin')}
                        </MuiLink>
                    </Stack>
                </form>
            )}
        </Box>
    )
}
