import {Box, Button, Drawer, DrawerProps, IconButton, Toolbar} from '@mui/material'
import {cloneElement, FC, PropsWithChildren, ReactElement, ReactNode, useEffect, useState} from 'react'
import AddButton from '../buttons/AddButton'
import {useRefetchTimestamp} from '@feg/ui'

type SidebarDrawerProps = {
    ToggleElement?: ReactElement
    drawerProps?: DrawerProps
    headline: ReactNode
    disableClosable?: boolean
    editButton?: string
    addButton?: string
    closeOnSubmit?: boolean
}

const SidebarDrawer: FC<PropsWithChildren<SidebarDrawerProps>> = ({
                                                                      disableClosable,
                                                                      editButton,
                                                                      addButton,
                                                                      headline,
                                                                      children,
                                                                      ToggleElement,
                                                                      drawerProps,
                                                                      closeOnSubmit
                                                                  }) => {
    const [open, setOpen] = useState<boolean>(false)
    const refetchTimestamp = useRefetchTimestamp()
    useEffect(
        () => {
            if (closeOnSubmit && refetchTimestamp) {
                setOpen(false)
            }
        },
        [closeOnSubmit, refetchTimestamp]
    )
    const toggle = () => setOpen(val => !val)
    return (
        <>
            {addButton && <AddButton onClick={toggle}>{addButton}</AddButton>}
            {editButton && <Button onClick={toggle} color={'info'} size={'small'}>{editButton}</Button>}
            {ToggleElement && cloneElement(ToggleElement, {
                onClick: toggle
            })}
            <Drawer open={open}
                    anchor={'right'}
                    {...(!disableClosable && {
                        onClose: () => setOpen(false)
                    })}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: ['80vw', '45vw']
                        }
                    }}
                    {...drawerProps}>
                <Toolbar>
                    {headline}
                    <div style={{flexGrow: 1}}></div>
                    <IconButton onClick={() => setOpen(false)}></IconButton>
                </Toolbar>
                <Box sx={{padding: 3}}>
                    {children}
                </Box>
            </Drawer>
        </>
    )
}
export default SidebarDrawer
