import {createRoot} from 'react-dom/client'
import "./i18next.ts"
import '@fontsource-variable/inter';
import {StrictMode} from "react";
import {FegReactRouter} from "./Router";
import {LicenseInfo} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey('7bef5ee5e5f7911da8e5f428bfb34756Tz03NDAyOCxFPTE3MjU1MDU0NjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <FegReactRouter/>
    </StrictMode>
    // <RouterProvider
    // <RouterProvider router={router}/>
)