import { ReactNode } from 'react'
import { domMax, LazyMotion } from 'framer-motion'

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
// @ts-ignore
// const loadFeatures = () => import('./features.js').then((res) => res.default);

type Props = {
  children: ReactNode;
};

export default function MotionLazyContainer({ children }: Props) {
  return (
    <LazyMotion features={domMax} strict={true}>
      {children}
    </LazyMotion>
  )
}
