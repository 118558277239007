import {Card} from '@mui/material'
import {FC, PropsWithChildren, ReactNode} from 'react'
import {DataGridPro as DataGrid, DataGridProProps as DataGridProps} from '@mui/x-data-grid-pro'
import {DataTableToolbar} from './DataTableToolbar'

type DataTableContainerProps = {
    ToolbarElements?: ReactNode
    dataTableProps: DataGridProps
}

const DataTableContainer: FC<PropsWithChildren<DataTableContainerProps>> = ({
                                                                                children,
                                                                                dataTableProps,
                                                                                ToolbarElements
                                                                            }) => {
    return (
        <Card sx={{
            minHeight: 350
        }}>
            <div style={{height: 'calc(100vh - 240px)', width: '100%'}}>

                <DataGrid {...dataTableProps}
                          slots={{
                              toolbar: () => (
                                  <DataTableToolbar>{ToolbarElements}</DataTableToolbar>
                              )
                          }}/>
                {children}
            </div>
        </Card>
    )
}

export default DataTableContainer
