// utils
import {defaultPreset} from '../utils/getColorPresets';
// @type
import {SettingsContextProps, ThemeColorPresets, ThemeDirection, ThemeMode,} from '../components/settings/type';
// config
import {defaultSettings} from '../config';
import {create} from "zustand";
import {persist} from "zustand/middleware";


// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
    ...defaultSettings,
    onChangeMode: () => {
    },
    onToggleMode: () => {
    },
    onChangeDirection: () => {
    },
    onChangeColor: () => {
    },
    onToggleStretch: () => {
    },
    onResetSetting: () => {
    },
    setColor: defaultPreset,
    colorOption: [],
};

export const useSettingsState = create<SettingsContextProps>()(
    persist(set => ({
        ...initialState,
        onChangeMode: (event) => set(state => ({
            ...state,
            themeMode: (event.target as HTMLInputElement).value as ThemeMode,
        })),
        onToggleMode: () => set(state => ({
            ...state,
            themeMode: state.themeMode === 'light' ? 'dark' : 'light',
        })),
        onChangeDirection: (event) => set(state => ({
            ...state,
            themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
        })),
        onChangeColor: (event) => set(state => ({
            ...state,
            themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
        })),
        onToggleStretch: () => set(state => ({
            ...state,
            themeStretch: !state.themeStretch
        })),
        onResetSetting: () => set({
            ...defaultSettings
        })
    }), {
        name: 'app-theme-settings'
    }))


// const SettingsContext = createContext(initialState);

// type SettingsProviderProps = {
//     children: ReactNode;
//     defaultSettings?: SettingsValueProps;
// };

// function SettingsProvider({
//                               children,
//                               defaultSettings = {} as SettingsValueProps,
//                           }: SettingsProviderProps) {
// const [settings, setSettings] = useSettingCookies(defaultSettings);
//
// const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSettings({
//         ...settings,
//         themeMode: (event.target as HTMLInputElement).value as ThemeMode,
//     });
// };
//
// const onToggleMode = () => {
//     setSettings({
//         ...settings,
//         themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
//     });
// };
//
// const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSettings({
//         ...settings,
//         themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
//     });
// };
//
// const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSettings({
//         ...settings,
//         themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
//     });
// };
//
// const onToggleStretch = () => {
//     setSettings({
//         ...settings,
//         themeStretch: !settings.themeStretch,
//     });
// };
//
// const onResetSetting = () => {
//     setSettings({
//         themeMode: initialState.themeMode,
//         themeDirection: initialState.themeDirection,
//         themeColorPresets: initialState.themeColorPresets,
//         themeStretch: initialState.themeStretch,
//     });
// };

//     return (
//         <>
//             {children}
//         </>
//     );
// }

// export {SettingsProvider};

// ----------------------------------------------------------------------

// function useSettingCookies(
//     defaultSettings: SettingsValueProps
// ): [SettingsValueProps, Dispatch<SetStateAction<SettingsValueProps>>] {
//     const [settings, setSettings] = useState<SettingsValueProps>(defaultSettings);
//
//     const onChangeSetting = () => {
//         Cookies.set(cookiesKey.themeMode, settings.themeMode, {expires: cookiesExpires});
//         Cookies.set(cookiesKey.themeDirection, settings.themeDirection, {expires: cookiesExpires});
//         Cookies.set(cookiesKey.themeColorPresets, settings.themeColorPresets, {
//             expires: cookiesExpires,
//         });
//         Cookies.set(cookiesKey.themeStretch, JSON.stringify(settings.themeStretch), {
//             expires: cookiesExpires,
//         });
//     };
//
//     useEffect(() => {
//         onChangeSetting();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [settings]);
//
//     return [settings, setSettings];
// }
