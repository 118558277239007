import {forwardRef, ReactNode, useEffect} from 'react'
// @mui
import {Box, BoxProps} from '@mui/material'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    children: ReactNode;
    meta?: ReactNode;
    title: string;
}

export const Page = forwardRef<HTMLDivElement, Props>(({children, meta, title, ...other}, ref) => {
        useEffect(() => {
            if (typeof document !== 'undefined') {
                document.title = title;
            }
        }, [title]);
        return (
            <Box ref={ref} {...other}>
                {children}
            </Box>
        )
    }
)
Page.displayName = 'Page'

