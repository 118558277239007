import {useTranslation} from 'react-i18next'
import {BaseTranslationFormType} from '../../generic/validation/BaseTranslationSchema'
import {
    TermCategoryListQuery,
    UpsertTermCategoryMutationVariables,
    useGenericMutation,
    useMutationResult,
    useUpsertTermCategoryMutation
} from '@feg/ui'
import {TextFieldElement} from 'react-hook-form-mui'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {FormStack} from "@feg/ui/src/common/form/FormStack";

type TermCategoryFormProps = {
    termCategory?: TermCategoryListQuery['term_category'][number]
}

export default function TermCategoryForm({termCategory}: TermCategoryFormProps) {
    const {t} = useTranslation()
    const {data, error, isPending: isFetching, mutateAsync} = useUpsertTermCategoryMutation()
    
    const {
        control,
        onSubmit,
    } = useGenericMutation<BaseTranslationFormType, UpsertTermCategoryMutationVariables, typeof mutateAsync>({
        id: termCategory?.id,
        mutateAsync,
        gqlSchemaName: '',
        defaultValues: {
            en: termCategory?.en,
            fr: termCategory?.fr || '',
            pt: termCategory?.pt || '',
            es: termCategory?.es || '',
            am: termCategory?.am || ''
        },
    })
    useMutationResult({data, error})
    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <TextFieldElement name={'en'} label={t('layout.locale.en')} control={control}/>
                <TextFieldElement name={'fr'} label={t('layout.locale.fr')} control={control}/>
                <TextFieldElement name={'es'} label={t('layout.locale.es')} control={control}/>
                <TextFieldElement name={'pt'} label={t('layout.locale.pt')} control={control}/>
                <TextFieldElement name={'am'} label={t('layout.locale.am')} control={control}/>
                <SubmitButton loading={isFetching}/>
            </FormStack>
        </form>
    )
}
