import {useSettingsState} from "@feg/ui/src/theme/contexts/SettingsContext";
import {useTranslation} from "react-i18next";
import {Page} from "@feg/ui/src/theme/components/Page";
import {Container, Grid} from "@mui/material";

export default function LandingPage() {
    const {themeStretch} = useSettingsState()
    const {t} = useTranslation()
    return (
        <Page title="General: App">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        {t('dashboard.headline')}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}