import {del, get, set, UseStore} from "idb-keyval";
import {StateStorage} from "zustand/middleware";


export const getIdbStorage = (store: UseStore): StateStorage => ({
    getItem: async (name) => {
        // Exit early on server
        if (typeof indexedDB === "undefined") {
            return null;
        }
        const value = await get(name, store);
        return value || null;
    },
    setItem: async (name, value) => {
        // Exit early on server
        if (typeof indexedDB === "undefined") {
            return;
        }
        return set(name, value, store);
    },
    removeItem: async (name) => {
        // Exit early on server
        if (typeof indexedDB === "undefined") {
            return;
        }
        await del(name, store);
    }
})




