import {Button, Stack} from '@mui/material'
import {useLocales} from "../../theme/hooks";

export default function LanguageSwitch() {
    const {allLang, onChangeLang, currentLang} = useLocales()
    console.log("inside lang switch",)
    return (
        <Stack direction={'row'} spacing={3}>
            {allLang.map(lang => (
                <Button
                    variant={lang.value === currentLang.value ? 'outlined' : 'text'}
                    onClick={async () => {
                        await onChangeLang(lang.value)
                    }}
                    key={lang.value}>{lang.label}</Button>
            ))}
        </Stack>
    )
}
