import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import AddEditMeasureButton from '../../../components/admin/measure/AddEditMeasureButton'
import MeasureList from '../../../components/admin/measure/MeasureList'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function MeasurePage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('measure.list.main')}
                  breadcrumbs={{
                      heading: t('measure.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('measure.list.main'), href: PATH_DASHBOARD.admin.measure}
                      ],
                      action: (
                          <AddEditMeasureButton/>
                      )
                  }}>
            <MeasureList/>
        </PageRoot>
    )
}

