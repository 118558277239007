import {ReactNode} from 'react'
// next
// hooks
// components
import {PATH_AUTH} from '../routes/paths'
import {useAuthenticationStatus} from '@nhost/react'
import {LoadingScreen} from "../components/LoadingScreen";
import {Navigate} from "react-router";

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function AuthGuard({children}: Props) {
    const {isLoading, isAuthenticated} = useAuthenticationStatus()
    if (isLoading) {
        // if (isLoading) {
        return <LoadingScreen/>
    }

    if (!isAuthenticated) {
        return (
            <Navigate to={PATH_AUTH.login}/>
        )
    }

    return <>{children}</>
}


