import {useTranslation} from 'react-i18next'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function PopulationPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('population.list.main')}>
            Coming soon...
        </PageRoot>
    )
}



