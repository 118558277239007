import {
    InterviewByPkQuery,
    InterviewDetailFragment,
    keysOfObject,
    UpdateProjectInterviewMutationVariables,
    useGenericMutation,
    useMutationResult,
    UsersQuery
} from '@feg/ui'
import {Box, Button, Card, CardContent, Grid, Stack, Typography} from '@mui/material'
import {DatePickerElement} from 'react-hook-form-mui'
import SubmitButton from '../generic/buttons/SubmitButton'
import {useTranslation} from 'react-i18next'
import ToggleInterviewStatus from './partials/ToggleInterviewStatus'
import pick from 'lodash/pick'
import {InterviewStatusIcon} from "@feg/ui/src/tss/InterviewStatusIcon";
import {CONFIG} from "@feg/ui/src/lib/config";
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {FormErrorMessage} from "@feg/ui/src/common/form/FormErrorMessage";
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import {useUpdateProjectInterviewMutation, useUsersQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type Schema = Pick<InterviewDetailFragment, 'date'> & {
    user_ids: string[]
}

const schema: Schema = {
    date: '',
    user_ids: []
}


type InterviewEditProps = {
    interview: NonNullable<InterviewByPkQuery['project_interview_by_pk']>
    isInline?: boolean
    afterSubmit?: () => void
}

export default function InterviewEdit({interview, isInline, afterSubmit}: InterviewEditProps) {
    const {t} = useTranslation()
    const {isPending, error, data, mutateAsync} = useUpdateProjectInterviewMutation()
    const {
        control,
        onSubmit,
    } = useGenericMutation<Schema, UpdateProjectInterviewMutationVariables, typeof mutateAsync>({
        id: interview.id,
        defaultValues: {
            ...pick(interview, keysOfObject(schema)),
            user_ids: interview.project_interview_researcher_mns?.map(i => i.user_id) ?? []
        },
        mutateAsync,
        gqlSchemaName: 'update_project_interview_by_pk',
        // struct: schema,
        prepareFormData: (formData, id) => {
            const userIds = formData.user_ids as string[]
            return {
                id: interview.id,
                set: {
                    ...pick(formData, ['date'])
                },
                researchData: userIds.map(userId => ({
                    user_id: userId,
                    project_interview_id: interview.id
                }))

            }
        },
        callbackAfterSubmit: () => afterSubmit?.()
    })
    useMutationResult({error, data})

    return (
        <form noValidate onSubmit={onSubmit}>
            <Grid container spacing={3}>
                <Grid item xs sm={isInline ? 12 : 6}>
                    <Card>
                        <CardContent>
                            <FormStack>
                                <FormErrorMessage control={control}/>
                                {!!interview && (
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <FormStack>
                                            <Typography variant={'h5'}>
                                                {interview.location?.name}
                                            </Typography>
                                            <Typography variant={'body1'}>
                                                <strong>{interview.wealth_group?.en}</strong>, {interview.location?.zone?.name}
                                            </Typography>
                                        </FormStack>

                                        <Button
                                            target={'_blank'}
                                            href={`${CONFIG.tssDomain}/project/${interview.project_id}/${interview.id}`}
                                            startIcon={<InterviewStatusIcon status={interview.status}
                                                                            is_done={interview.project_interview_base?.is_done}
                                                                            hasUploadId={interview.project_interview_base?.id}/>}>{t('openInterview')}</Button>

                                    </Stack>
                                )}
                                <DatePickerElement name={'date'}
                                                   label={t('interview.form.date')}
                                                   textReadOnly
                                                   control={control}
                                />
                                <AutocompleteFetchQuery
                                    name={'user_ids'}
                                    label={t('interview.form.researcher')}
                                    control={control}
                                    asyncQuery={useUsersQuery.fetcher()}
                                    queryKey={useUsersQuery.getKey()}
                                    matchId={true}
                                    gqlSchema={'users'}
                                    autocompleteProps={{
                                        multiple: true,
                                        getOptionLabel: (option: UsersQuery['users'][number] | string) => {
                                            if (typeof option === 'string') {
                                                return option
                                            }
                                            if (!option.user_profile) {
                                                return ''
                                            }
                                            return `${option.user_profile?.first_name} ${option.user_profile?.last_name} (${option.email})`
                                        }
                                    }}
                                />
                            </FormStack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs sm={isInline ? 12 : 6}>
                    <SubmitButton loading={isPending} size={'large'} fullWidth variant={'contained'} sx={{
                        mt: 3
                    }}/>
                    <Box mt={3} justifyContent={'end'} display={'flex'}>
                        <ToggleInterviewStatus id={interview.id} status={interview.status} allowDelete={isInline}/>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
