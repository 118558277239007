import {useAuthenticated} from "@nhost/react";
import {useEffect} from "react";
import {LoginForm} from "../../common/auth/LoginForm";
import {Link, useNavigate} from "react-router-dom";
import {PageRootSimpleSpa} from "../../common/layout/PageRootSimpleSpa";

export default function Login() {
    const navigate = useNavigate()
    const isAuthenticated = useAuthenticated()
    useEffect(
        () => {
            if (isAuthenticated) {
                navigate('/')
            }
        },
        [isAuthenticated, navigate]
    )

    return (
        <PageRootSimpleSpa title={'Login'}>
            <LoginForm
                LinkComponent={Link}
                linkHrefTo
                onSuccess={async () => {
                    navigate('/')
                }}/>
        </PageRootSimpleSpa>
    )
}