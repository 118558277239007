import {UpsertWealthGroupMutationVariables, useGenericMutation, useMutationResult, WealthGroupListQuery} from '@feg/ui'
import {TextFieldElement} from 'react-hook-form-mui'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {BaseTranslationFormType} from '../../generic/validation/BaseTranslationSchema'
import {useTranslation} from 'react-i18next'
import {TextFieldNumberFormat} from "@feg/ui/src/common/form/TextFieldNumberFormat";
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {useUpsertWealthGroupMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type WealthGroupFormProps = {
    wealthGroup?: WealthGroupListQuery['wealth_group'][number]
}

export default function WealthGroupForm({wealthGroup}: WealthGroupFormProps) {
    const {t} = useTranslation()
    const {data, error, isPending, mutateAsync} = useUpsertWealthGroupMutation()
    const {
        control,
        onSubmit,
    } = useGenericMutation<BaseTranslationFormType, UpsertWealthGroupMutationVariables, typeof mutateAsync>({
        id: wealthGroup?.id,
        mutateAsync,
        gqlSchemaName: 'insert_wealth_group_one',
        defaultValues: {
            en: wealthGroup?.en,
            fr: wealthGroup?.fr || '',
            pt: wealthGroup?.pt || '',
            es: wealthGroup?.es || '',
            sorting: wealthGroup?.sorting || 0
        },
    })
    useMutationResult({data, error})

    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <TextFieldElement name={'en'} label={t('layout.locale.en')} control={control}/>
                <TextFieldNumberFormat name={'sorting'} label={t('sorting')} control={control as any}/>
                <TextFieldElement name={'fr'} label={t('layout.locale.fr')} control={control}/>
                <TextFieldElement name={'es'} label={t('layout.locale.es')} control={control}/>
                <TextFieldElement name={'pt'} label={t('layout.locale.pt')} control={control}/>
                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
