import { GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { FC, PropsWithChildren } from 'react'
import { Toolbar } from '@mui/material'

export const DataTableToolbar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Toolbar sx={{ bgcolor: 'grey.200' }}>
      {children}
      <span style={{ flexGrow: 1 }} />
      {/*<GridToolbarColumnsButton />*/}
      <GridToolbarFilterButton sx={{ mr: 2 }} />
      <GridToolbarDensitySelector sx={{ mr: 2 }} />
      <GridToolbarExport sx={{ mr: 2 }} />
    </Toolbar>
  )
}
