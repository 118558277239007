import {FC, PropsWithChildren} from 'react'
import {CollapseDrawerProvider} from '../../theme/contexts/CollapseDrawerContext'
import ThemeColorPresets from '../../theme/components/ThemeColorPresets'
import NotistackProvider from '../../theme/components/NotistackProvider'
import AppGlobalStyles from '../../theme/theme/AppGlobalStyles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import AppThemeProvider from "../../theme/theme";
import MotionLazyContainer from "../../theme/components/animate/MotionLazyContainer";
import {SettingsValueProps} from "../../theme/components/settings/type";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {OnlineProvider} from "../../provider";

type AppSetupContainerProps = {
    settings?: SettingsValueProps,
}


export const AppSetupContainer: FC<PropsWithChildren<AppSetupContainerProps>> = ({
                                                                                     children,
                                                                                     settings
                                                                                 }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppThemeProvider>
                <CollapseDrawerProvider>
                    <ThemeColorPresets>
                        <NotistackProvider>
                            <OnlineProvider>
                                <MotionLazyContainer>
                                    <AppGlobalStyles/>
                                    {children}
                                </MotionLazyContainer>
                            </OnlineProvider>
                        </NotistackProvider>
                    </ThemeColorPresets>
                </CollapseDrawerProvider>
            </AppThemeProvider>
        </LocalizationProvider>
    )
}
