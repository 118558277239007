import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import CountryRegionForm from './CountryRegionForm'
import { CountryRegionQuery } from '@feg/ui'

type AddEditCountryDistrictButtonProps = {
  district?: CountryRegionQuery['country_region'][number]
}
export default function AddEditCountryRegionButton({ district }: AddEditCountryDistrictButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      addButton={!district ? t('countryRegion.list.add') : undefined}
      editButton={district ? t('countryRegion.list.edit') : undefined}
      headline={district ? t('countryRegion.list.edit') : t('countryRegion.list.add')} closeOnSubmit>
      <CountryRegionForm region={district} />
    </SidebarDrawer>
  )
}
