import { FC, PropsWithChildren } from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { useTranslation } from 'react-i18next'

type SubmitButtonProps = LoadingButtonProps & {
  buttonLabel?: string
}
const SubmitButton: FC<PropsWithChildren<SubmitButtonProps>> = ({ buttonLabel, children, ...rest }) => {
  const { t } = useTranslation()

  return <LoadingButton type={'submit'} {...rest}>{buttonLabel || children || t('layout.submitButton')}</LoadingButton>
}
export default SubmitButton
