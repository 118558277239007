import { useTranslation } from 'react-i18next'
import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import AddInterviewsForm from './AddInterviewsForm'
import AddButton from '../../generic/buttons/AddButton'

type AddInterviewsButtonProps = {
  projectId: string
}

export default function AddInterviewsButton({ projectId }: AddInterviewsButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      closeOnSubmit={true}
      ToggleElement={<AddButton variant={'text'}>{t('interview.add')}</AddButton>}
      headline={t('interview.add')}>
      <AddInterviewsForm projectId={projectId} />
    </SidebarDrawer>
  )
}
