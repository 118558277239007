import { compareSync, hashSync } from 'bcryptjs'

const salt = 10
export const encryptString = (text: string) => {
  return hashSync(text, salt)
}

export const checkString = (phrase: string, text: string) => {
  return compareSync(phrase, text)
}
