import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {TermCategoryListQuery, useGenericQuery} from '@feg/ui'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditTermCategoryButton from './AddEditTermCategoryButton'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useState} from "react";
import {useDeleteTermCategoryMutation, useTermCategoryListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const GridColums: GridColDef<TermCategoryListQuery['term_category'][number]>[] = [{
    field: 'en',
    headerName: 'English',
    flex: 1
}, {
    field: 'fr',
    headerName: 'French',
    flex: 1
}, {
    field: 'es',
    headerName: 'Spanish',
    flex: 1
}, {
    field: 'pt',
    headerName: 'Portuguese',
    flex: 1
}, {
    field: 'am',
    headerName: 'Amharic',
    flex: 1
}, {
    field: 'id',
    headerName: 'ID',
    flex: 1
}]

export default function TermCategoryList() {
    const {data, isFetching, isLoading, error, refetch} = useTermCategoryListQuery()
    const [selectedRows, setSelectedRows] = useState<TermCategoryListQuery['term_category']>([])
    useGenericQuery({
        refetch, setSelectedRows, isFetching
    })
    const {mutateAsync, isPending} = useDeleteTermCategoryMutation()
    const rows = data?.term_category || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length === 1 && (
                        <AddEditTermCategoryButton termCategory={selectedRows[0]}/>
                    )}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            count={selectedRows.length}
                            isLoading={isPending}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows.map(i => i.id)
                            })}
                        />
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}
