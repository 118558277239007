import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import { ZoneListQuery } from '@feg/ui'
import ZoneForm from './ZoneForm'

type AddEditZoneButtonProps = {
  zone?: ZoneListQuery['zone'][number]
}
export default function AddEditZoneButton({ zone }: AddEditZoneButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      addButton={!zone ? t('zone.list.add') : undefined}
      editButton={zone ? t('zone.list.edit') : undefined}
      headline={zone ? t('zone.list.edit') : t('zone.list.add')}
      closeOnSubmit>
      <ZoneForm zone={zone} />
    </SidebarDrawer>
  )
}
