import {useTranslation} from 'react-i18next'
import {
    InsertProjectMutationVariables,
    LocationQuery,
    Project_Interview_Insert_Input,
    useGenericMutation,
    useMutationResult,
    UsersQuery
} from '@feg/ui'
import SubmitButton from '../generic/buttons/SubmitButton'
import WealthGroupCheckboxGroup from './partials/WealthGroupCheckboxGroup'
import {TextFieldElement} from 'react-hook-form-mui'
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import {FormStack} from '@feg/ui/src/common/form/FormStack'
import {useInsertProjectMutation, useLocationQuery, useUsersQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const schema = {
    name: '',
    supervisor_user_id: '',
    location_ids: [],
}

type Schema = typeof schema & {
    wealthGroups?: string[]
}

type AddProjectFormProps = {
    project?: any
}

export default function AddProjectForm({project}: AddProjectFormProps) {
    const {t} = useTranslation()
    const {isPending, data, error, mutateAsync} = useInsertProjectMutation()
    const {
        control,
        onSubmit,
        setValue
    } = useGenericMutation<Schema, InsertProjectMutationVariables, typeof mutateAsync>({
        id: project?.id,
        defaultValues: {
            name: project?.name,
            supervisor_user_id: project?.supervisor_user_id || null,
            location_ids: []
        },
        mutateAsync,
        gqlSchemaName: 'insert_project_one',
        prepareFormData: (formData, id) => {
            let interviewData: Project_Interview_Insert_Input[] = []
            if (formData.location_ids?.length) {
                formData.location_ids.forEach(locationId => {
                    formData.wealthGroups?.forEach(wealthGroupId => {
                        interviewData.push({
                            wealth_group_id: wealthGroupId,
                            location_id: locationId
                        })
                    })
                })
            }
            return {
                object: {
                    name: formData.name,
                    supervisor_user_id: formData.supervisor_user_id,
                    ...(formData.location_ids?.length && formData.wealthGroups?.length && {
                        project_interviews: {
                            data: interviewData
                        }
                    })
                }
            }
        }
    })
    useMutationResult({data, error})
    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <TextFieldElement name={'name'} control={control} label={t('project.form.name')}/>
                <AutocompleteFetchQuery
                    name={'supervisor_user_id'}
                    label={t('project.form.supervisor')}
                    control={control}
                    asyncQuery={useUsersQuery.fetcher()}
                    gqlSchema={'users'}
                    matchId={true}
                    autocompleteProps={{
                        getOptionLabel: (option: UsersQuery['users'][number] | string) => {
                            console.log(option)
                            if (typeof option === 'string') {
                                return option
                            }
                            if (!option.user_profile) {
                                return ''
                            }
                            return `${option.user_profile?.first_name} ${option.user_profile?.last_name} (${option.email})`
                        }
                    }}
                />
                <WealthGroupCheckboxGroup control={control} name={'wealthGroups'} setValue={setValue}/>

                <AutocompleteFetchQuery
                    name={'location_ids'}
                    control={control}
                    asyncQuery={useLocationQuery.fetcher()}
                    gqlSchema={'location'}
                    matchId={true}
                    autocompleteProps={{
                        multiple: true,
                        getOptionLabel: (option: LocationQuery['location'][number] | string) => {
                            if (typeof option === 'string') {
                                return option
                            }
                            if (!option) {
                                return ''
                            }
                            return `${option.name} (${option.country_region?.name} - ${option.country_region?.country?.en})`
                        }
                    }}
                />

                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
