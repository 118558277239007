import {useSnackbar} from 'notistack';
import {ForwardRefExoticComponent, PropsWithChildren, useRef, useState} from 'react';
import {alpha} from '@mui/material/styles';
import {Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Stack, Typography} from '@mui/material';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';

import {Close, Settings as SettingsIcon} from "@mui/icons-material";
import IconButtonAnimate from '../../../components/animate/IconButtonAnimate';
import Settings from "../../../components/settings/Settings";
import {useSignOut, useUserData} from "@nhost/react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useIsOnline} from "../../../../provider";
import {useTranslation} from "react-i18next";


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        linkTo: '/',
    }
];

type AccountPopoverProps = PropsWithChildren<{
    LinkComponent?: ForwardRefExoticComponent<any>
    additionalLinks?: {
        label: string
        href: string
    }[]
}>


export default function AccountPopover({
                                           children,
                                           additionalLinks = [],
                                           LinkComponent,
                                       }: AccountPopoverProps) {
    const {t} = useTranslation()

    const anchorRef = useRef(null);
    const {signOut} = useSignOut()
    const user = useUserData()

    const isMountedRef = useIsMountedRef();

    const {enqueueSnackbar} = useSnackbar();

    const [open, setOpen] = useState(false);
    const [settingOpen, setSettingOpen] = useState<boolean>(false)
    const {isOnline} = useIsOnline()
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            await signOut();

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', {variant: 'error'});
        }
    };

    return (
        <>
            <IconButtonAnimate
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <MyAvatar/>
            </IconButtonAnimate>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{width: 220}}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Grid2 container direction={'row'}>
                        <Grid2 xs>
                            {!user ? (
                                <Typography variant="subtitle1" noWrap>
                                    Not logged in
                                </Typography>
                            ) : (
                                <>
                                    <Typography variant="subtitle1" noWrap>
                                        {user?.displayName}
                                    </Typography>
                                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                                        {user?.email}
                                    </Typography>
                                </>
                            )}
                        </Grid2>
                        <Grid2 xs={'auto'}>
                            <IconButton onClick={() => {
                                setSettingOpen(true)
                                handleClose()
                            }}><SettingsIcon/></IconButton>
                        </Grid2>
                    </Grid2>
                </Box>

                <Divider/>
                <Stack spacing={0.5} sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={handleClose}
                            to={option.linkTo}
                            component={LinkComponent as any}
                            sx={{typography: 'body2', py: 1, px: 2, borderRadius: 1}}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                    {additionalLinks?.map(option => (
                        <MenuItem
                            key={option.label}
                            to={option.href}
                            onClick={handleClose}
                            sx={{typography: 'body2', py: 1, px: 2, borderRadius: 1}}
                            component={LinkComponent as any}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                    {children}
                </Stack>
                {isOnline && (
                    <>
                        <Divider/>
                        <MenuItem
                            onClick={handleLogout}
                            sx={{typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1}}
                        >
                            {!user ? t('auth.headlineLogin') : t('auth.logout')}
                        </MenuItem>
                    </>
                )}
            </MenuPopover>
            <Dialog open={settingOpen} fullWidth>
                <DialogTitle display={'flex'} justifyContent={'flex-end'}>
                    <IconButton onClick={() => setSettingOpen(false)}><Close/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Settings/>
                </DialogContent>
            </Dialog>
        </>
    );
}
