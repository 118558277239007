import {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from 'react'
import {onlineManager} from "@tanstack/react-query";

type OnlineContext = {
    isOnline: boolean
}
const Context = createContext<OnlineContext>({isOnline: onlineManager.isOnline()})

export const useIsOnline = () => useContext(Context)


export const OnlineProvider: FC<PropsWithChildren> = ({children}) => {
    const [isOnline, setOnlineStatus] = useState(onlineManager.isOnline())

    useEffect(() => {
        onlineManager.subscribe(() => {
            setOnlineStatus(onlineManager.isOnline())
        })

        // const toggleOnlineStatus = () => {
        //     setOnlineStatus(navigator.onLine);
        // }
        //
        // window.addEventListener('online', toggleOnlineStatus)
        // window.addEventListener('offline', toggleOnlineStatus)
        //
        // return () => {
        //     window.removeEventListener('online', toggleOnlineStatus)
        //     window.removeEventListener('offline', toggleOnlineStatus)
        // }
    }, [])

    return <Context.Provider value={{
        isOnline
    }}>{children}</Context.Provider>
}
