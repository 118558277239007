import {CheckboxButtonGroup, CheckboxButtonGroupProps} from 'react-hook-form-mui'
import {Skeleton} from '@mui/material'
import {FieldValues, UseFormSetValue} from 'react-hook-form'
import {useEffect, useMemo} from 'react'
import {useWealthGroupListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type WealthGroupCheckboxGroupProps<T extends FieldValues> = Omit<CheckboxButtonGroupProps<T>, 'options'> & {
    setValue: UseFormSetValue<any>
}

export default function WealthGroupCheckboxGroup<TFieldValues extends FieldValues>({
                                                                                       name,
                                                                                       control,
                                                                                       setValue
                                                                                   }: WealthGroupCheckboxGroupProps<TFieldValues>) {
    const {data, isFetching} = useWealthGroupListQuery()
    const options: { id: string, label: string }[] = useMemo(
        () => data?.wealth_group?.map(wealthGroup => ({
            id: wealthGroup.id,
            label: wealthGroup.en
        })) || [],
        [data?.wealth_group]
    )

    useEffect(
        () => {
            if (options.length) {
                // @ts-ignore
                setValue(name, options.map(i => i.id)) // preset all checkboxes
            }
        }, [name, options, setValue]
    )

    if (isFetching) {
        return <Skeleton/>
    }
    return (
        <CheckboxButtonGroup options={options} name={name} control={control}/>
    )
}
