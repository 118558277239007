import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import {useState} from "react";
import AuthGuard from "../../guards/AuthGuard";
import {MainStyle, RootStyle} from "./RootStyle";
import DashboardHeader from "./header";
import DashboardNavbar from "./navbar";
import {Link, Outlet} from "react-router-dom";

export function AdminDashboardLayout() {
    const {collapseClick} = useCollapseDrawer()
    const [open, setOpen] = useState(false)

    return (
        <AuthGuard>
            <RootStyle>
                <DashboardHeader onOpenSidebar={() => setOpen(true)} LinkComponent={Link}/>

                <DashboardNavbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>

                <MainStyle collapseClick={collapseClick}>
                    <Outlet/>
                </MainStyle>
            </RootStyle>
        </AuthGuard>
    )
}