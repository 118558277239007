import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import AddProjectButton from '../../../components/project/AddProjectButton'
import ProjectList from '../../../components/project/ProjectList'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function ProjectsPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('layout.drawer.projectList')}
                  breadcrumbs={{
                      heading: t('layout.drawer.projectList'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('layout.drawer.projectList'), href: PATH_DASHBOARD.general.projectList}
                      ],
                      action: (
                          <AddProjectButton/>
                      )
                  }}>
            <ProjectList/>
        </PageRoot>
    )
}

