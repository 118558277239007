// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_API = '/syncTssDb'
const ROOTS_TSS = '/tss'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
}

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components'
}

export const PATH_TSS = {
    root: ROOTS_TSS
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD + '/app',
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
        projectList: path(ROOTS_DASHBOARD, '/projects')
    },
    project: {
        edit: path(ROOTS_DASHBOARD, '/projects/edit/'),
        view: path(ROOTS_DASHBOARD, '/projects/')
    },
    interview: {
        edit: path(ROOTS_DASHBOARD, '/interviews/edit/')
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        cards: path(ROOTS_DASHBOARD, '/user/cards'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        settings: path(ROOTS_DASHBOARD, '/user/settings'),
        account: path(ROOTS_DASHBOARD, '/user/account')
    },
    location: {
        root: path(ROOTS_DASHBOARD, '/location'),
        countryList: path(ROOTS_DASHBOARD, '/location/country'),
        countryRegion: path(ROOTS_DASHBOARD, '/location/region'),
        list: path(ROOTS_DASHBOARD, '/location/list'),
        zoneList: path(ROOTS_DASHBOARD, '/location/zone'),
        population: path(ROOTS_DASHBOARD, '/location/population')
    },
    admin: {
        root: path(ROOTS_DASHBOARD, '/admin'),
        wealthGroupList: path(ROOTS_DASHBOARD, '/admin/wealth-group'),
        termCategory: path(ROOTS_DASHBOARD, '/admin/category-terms'),
        term: path(ROOTS_DASHBOARD, '/admin/term'),
        measure: path(ROOTS_DASHBOARD, '/admin/measure'),
        crops: path(ROOTS_DASHBOARD, '/admin/crop-seasons')
    }
}

export const PATH_API = {
    user: {
        create: path(ROOTS_API, '/user/create')
    }
}
export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction'
