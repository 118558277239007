import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import { WealthGroupListQuery } from '@feg/ui'
import WealthGroupForm from './WealthGroupForm'

type AddEditWealthGroupButtonProps = {
  wealthGroup?: WealthGroupListQuery['wealth_group'][number]
}
export default function AddEditWealthGroupButton({ wealthGroup }: AddEditWealthGroupButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      addButton={!wealthGroup ? t('wealthGroup.list.add') : undefined}
      editButton={wealthGroup ? t('wealthGroup.list.edit') : undefined}
      headline={wealthGroup ? t('wealthGroup.list.edit') : t('wealthGroup.list.add')}
      closeOnSubmit>
      <WealthGroupForm wealthGroup={wealthGroup} />
    </SidebarDrawer>
  )
}
