export const pick = <T extends object, K extends keyof T>(
    base: T,
    keys: K[]
) => {
    const entries = keys.map((key) => [key, base[key]])
    return Object.fromEntries(entries)
}

export const keysOfObject = <T extends object>(obj: T) => {
    return Object.keys(obj) as (keyof T)[]
}