import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {GridColDef} from '@mui/x-data-grid-pro'
import {useGenericQuery, WealthGroupListQuery} from '@feg/ui'
import AddEditWealthGroupButton from './AddEditWealthGroupButton'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useState} from "react";
import {useDeleteWealthGroupMutation, useWealthGroupListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

const GridColums: GridColDef<WealthGroupListQuery['wealth_group'][number]>[] = [{
    field: 'en',
    headerName: 'English',
    flex: 1
}, {
    field: 'sorting',
    headerName: 'Sorting',
    flex: 1
}, {
    field: 'fr',
    headerName: 'French',
    flex: 1
}, {
    field: 'es',
    headerName: 'Spanish',
    flex: 1
}, {
    field: 'pt',
    headerName: 'Portuguese',
    flex: 1
}]

export default function WealthGroupList() {
    const {data, isFetching, error, refetch} = useWealthGroupListQuery()
    const [selectedRows, setSelectedRows] = useState<WealthGroupListQuery['wealth_group']>([])

    useGenericQuery({
        isFetching, setSelectedRows, refetch
    })
    const {mutateAsync, isPending} = useDeleteWealthGroupMutation()
    const rows = data?.wealth_group || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length === 1 && (
                        <AddEditWealthGroupButton wealthGroup={selectedRows[0]}/>
                    )}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            count={selectedRows.length} isLoading={isPending}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows.map(i => i.id)
                            })}/>
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}
        />
    )
}
