// hooks
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, {Props as AvatarProps} from './Avatar';
import {useAuthenticationStatus, useUserData} from "@nhost/react";
import {useIsOnline} from "../../provider";
import {Badge} from "@mui/material";
import {PersonOff, SignalWifiOff} from "@mui/icons-material";

// ----------------------------------------------------------------------

export default function MyAvatar({...other}: AvatarProps) {
    const user = useUserData();
    const {isOnline} = useIsOnline()
    const {isAuthenticated} = useAuthenticationStatus()
    const AvatarEl = (
        <Avatar
            alt={user?.displayName as string}
            color={user?.displayName ? 'default' : createAvatar(user?.displayName as string).color}
            {...other}
        >
            {createAvatar(user?.displayName as string).name}
        </Avatar>
    );

    if (!isOnline) {
        return (
            <Badge badgeContent={<SignalWifiOff color={'warning'}/>} overlap={'circular'}>
                {AvatarEl}
            </Badge>
        )
    }
    if (!isAuthenticated) {
        return (
            <Badge badgeContent={<PersonOff color={'warning'}/>} overlap={'circular'}>
                {AvatarEl}
            </Badge>
        )
    }
    return AvatarEl;
}
