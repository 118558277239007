import {PATH_DASHBOARD, ProjectByPkQuery} from '@feg/ui'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import {Box, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material'
import AddInterviewsButton from '../../interview/partials/AddInterviewsButton'
import {getUserNameShort} from "@feg/ui/src/lib/helpers/userNameHelper";
import {InterviewStatusIcon} from "@feg/ui/src/tss/InterviewStatusIcon";
import SidebarDrawer from "../../generic/drawers/SidebarDrawer";
import InterviewEdit from "../../interview/InterviewEdit";
import {Link} from "react-router-dom";

type ProjectInterviewListProps = {
    projectId: string
    interviews: NonNullable<ProjectByPkQuery['project_by_pk']>['project_interviews']
}


export default function ProjectInterviewList({interviews, projectId}: ProjectInterviewListProps) {
    const grouped = groupBy(interviews, interview => interview.location.name)

    return (
        <div>
            <Box display={'flex'} justifyContent={'flex-start'} padding={2}>
                <AddInterviewsButton projectId={projectId}/>
            </Box>
            <TableContainer>

                <Table>
                    <TableBody>
                        {Object.keys(grouped).map(location => {
                            return (
                                <TableRow key={location}>
                                    <TableCell component={'th'} sx={{fontWeight: 'bold'}}>{location}</TableCell>
                                    {sortBy(grouped[location], 'wealth_group.sorting').map(interview => {
                                        return (
                                            <TableCell size={'small'} align={'left'} sx={{verticalAlign: 'top'}}
                                                       key={interview.id}>
                                                <Button
                                                    component={Link}
                                                    startIcon={<InterviewStatusIcon status={interview.status}
                                                                                    is_done={interview.project_interview_base?.is_done}
                                                                                    hasUploadId={interview.project_interview_base?.id}/>}
                                                    to={PATH_DASHBOARD.interview.edit + interview.id}>

                                                    {interview.wealth_group.en}
                                                </Button>
                                                <SidebarDrawer headline={'Interview'}
                                                               ToggleElement={<Button>[edit]</Button>}>
                                                    <InterviewEdit interview={interview as any} isInline={true}/>
                                                </SidebarDrawer>
                                                {interview.project_interview_researcher_mns.length > 0 && (
                                                    <Typography variant={'caption'}><br/>
                                                        {interview.project_interview_researcher_mns.map(mn => getUserNameShort(mn.user)).join(', ')}
                                                    </Typography>
                                                )}

                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

