import {useState} from 'react'
import {UserOfList} from './usersTypings'
import {useSnackbar} from 'notistack'
import {useSendVerificationEmail} from '@nhost/react'
import {ConfirmButton} from "@feg/ui/src/common/buttons";

export default function InviteUsersButton({users}: { users: UserOfList[] }) {
    const [isFetching, setIsFetching] = useState<boolean>()
    const {sendEmail} = useSendVerificationEmail()
    const {enqueueSnackbar} = useSnackbar()
    return (
        <ConfirmButton
            buttonProps={{
                color: 'warning',
                loading: isFetching
            }}
            onConfirm={async () => {
                setIsFetching(true)
                let redirectTo = `https://interview.headigital.app/auth/forgot-password`;
                console.log(redirectTo)
                const filteredUsers = users
                    .filter(i => !i.emailVerified);
                const promises = filteredUsers
                    .map(user => {
                        console.log(user)
                        return sendEmail(user.email, {
                            redirectTo: redirectTo
                        })
                    })
                const settled = await Promise.allSettled(promises)
                settled.forEach((res, index) => {
                    if (res.status === "fulfilled" && res?.value?.error?.message) {
                        enqueueSnackbar(res?.value?.error?.message, {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar(`Email sent to ${filteredUsers[index]?.email}`, {
                            variant: 'success'
                        })
                    }
                })
                setIsFetching(false)
            }}
            message={`Do you want to invite ${users.length} user(s)?`}>INVITE</ConfirmButton>
    )
}

