import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditCountryRegionButton from './AddEditCountryRegionButton'
import {getCountryRegionNested} from './countryRegionHelper'
import {CountryRegionQuery, useGenericQuery} from '@feg/ui'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useCountryRegionQuery, useDeleteCountryRegionMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<CountryRegionQuery['country_region'][number]>[] = [{
    field: 'name',
    headerName: 'Name',
    flex: 1
}, {
    field: 'parent_id',
    headerName: 'Parent Region',
    flex: 1,
    valueGetter: ({row}) => getCountryRegionNested(row)
}, {
    field: 'country_id',
    headerName: 'Country',
    flex: 1,
    valueGetter: ({row}) => row.country?.en
}]

export default function CountryRegionList() {
    const {isFetching, data, refetch} = useCountryRegionQuery()
    const [selectedRows, setSelectedRows] = useState<CountryRegionQuery['country_region']>([])
    useGenericQuery({
        refetch, isFetching, setSelectedRows
    })
    const {isPending, mutateAsync} = useDeleteCountryRegionMutation()
    const rows = data?.country_region || []
    return (
        <DataTableContainer ToolbarElements={
            <>
                {selectedRows?.length === 1 && (
                    <AddEditCountryRegionButton district={selectedRows[0]}/>
                )}
                {selectedRows?.length > 0 && (
                    <DeleteTableRowButton isLoading={isPending}
                                          refetch={refetch}
                                          count={selectedRows?.length}
                                          mutateAsync={() => mutateAsync({
                                              ids: selectedRows.map(i => i.id)
                                          })}
                    />
                )}
            </>
        } dataTableProps={{
            loading: isFetching,
            columns: GridColums,
            rows,
            checkboxSelection: true,
            rowSelectionModel: selectedRows?.map(i => i.id),
            onRowSelectionModelChange: (selectionModel) => {
                const modelValues = rows.filter(i => selectionModel.includes(i.id))
                setSelectedRows(modelValues)
            }
        }}>
        </DataTableContainer>
    )
}
