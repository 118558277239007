import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import CropSeasonList from '../../../components/admin/cropSeason/CropSeasonList'
import AddEditCropSeasonButton from '../../../components/admin/cropSeason/AddEditCropSeasonButton'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function CropSeasonsPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('crops.list.main')}
                  breadcrumbs={{
                      heading: t('crops.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('crops.list.main'), href: PATH_DASHBOARD.admin.crops}
                      ],
                      action: (
                          <AddEditCropSeasonButton/>
                      )
                  }}>
            <CropSeasonList/>
        </PageRoot>
    )
}

