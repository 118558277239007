import {useState} from 'react'
import {DeepPartial, FieldValues, useForm} from 'react-hook-form'

type UseGenericMutationProps<T, K, Q> = {
    id?: string
    defaultValues: DeepPartial<T>
    // GqlMutation: DocumentNode | string
    gqlSchemaName: string
    prepareFormData?: (data: T, id?: string) => K
    callbackAfterSubmit?: () => void | Promise<void>
    mutateAsync: Q
}

export function useGenericMutation<T extends FieldValues, K extends {} | undefined, Q extends {}>({
                                                                                                      id,
                                                                                                      defaultValues,
                                                                                                      // GqlMutation,
                                                                                                      mutateAsync,
                                                                                                      gqlSchemaName,
                                                                                                      prepareFormData,
                                                                                                      callbackAfterSubmit,
                                                                                                  }: UseGenericMutationProps<T, K, Q>) {
    const [itemId, setItemId] = useState<string | undefined>(id)
    const {control, handleSubmit, setValue, register, watch} = useForm<T>({
        defaultValues
    })
    const onSubmit = handleSubmit(async (formData) => {
        const variables = typeof prepareFormData === 'function' ? {
            ...prepareFormData(formData, itemId)
        } : {
            object: {
                id: itemId,
                ...formData
            }
        }
        // @ts-ignore
        const data = await mutateAsync({
            ...variables
        })
        if (data?.[gqlSchemaName]?.id) {
            setItemId(data[gqlSchemaName].id)
        }
        if (typeof callbackAfterSubmit === 'function') {
            await callbackAfterSubmit()
        }
    })
    return {
        onSubmit, control, setValue, itemId, register, watch
    }
}
