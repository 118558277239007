import {NhostProvider} from "@nhost/react";
import {appNhostClient} from "../lib";
import {NhostSimpleReactQueryProvider} from "../lib/nhost/NhostSimpleReactQueryProvider";
import {AppSetupContainer} from "../common/layout/AppSetupContainer";
import {Outlet} from "react-router-dom";

export function ViteAdminGlobalProvider() {
    // todo ContainerAuth needs to be handled differently
    // todo handle AllTssVariableSetup on per page basis?
    return (
        <NhostProvider nhost={appNhostClient}>
            <NhostSimpleReactQueryProvider>
                <AppSetupContainer>
                    <Outlet/>
                </AppSetupContainer>
            </NhostSimpleReactQueryProvider>
        </NhostProvider>
    )
}