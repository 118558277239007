import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {useGenericQuery, ZoneListQuery} from '@feg/ui'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditZoneButton from './AddEditZoneButton'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useDeleteZoneMutation, useZoneListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<ZoneListQuery['zone'][number]>[] = [{
    field: 'name',
    headerName: 'Zone',
    flex: 1
}, {
    field: 'country_id',
    headerName: 'Country',
    valueGetter: ({row}) => row.country?.en || '',
    flex: 1
}]

export default function ZoneList() {
    const {data, isFetching, refetch, error} = useZoneListQuery()
    const [selectedRows, setSelectedRows] = useState<ZoneListQuery["zone"]>([])

    useGenericQuery({
        isFetching, refetch, setSelectedRows
    })
    const {mutateAsync, isPending} = useDeleteZoneMutation()

    const rows = data?.zone || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length === 1 && <AddEditZoneButton zone={selectedRows[0]}/>}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            count={selectedRows.length} isLoading={isPending}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows.map(i => i.id)
                            })}/>
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}
