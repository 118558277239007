import {ChangePasswordForm} from "../../common/auth/ChangePasswordForm";
import {Link, useNavigate} from "react-router-dom";
import {PageRootSimpleSpa} from "../../common/layout/PageRootSimpleSpa";

export default function ChangePassword(): JSX.Element {
    const navigate = useNavigate()

    return (
        <PageRootSimpleSpa title={'Login'}>
            <ChangePasswordForm
                onSuccess={async () => {
                    navigate('/')
                }}
                LinkComponent={Link}
                linkHrefTo
            />
        </PageRootSimpleSpa>
    )
}