import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import { TermCategoryListQuery } from '@feg/ui'
import TermCategoryForm from './TermCategoryForm'

type AddEditCountryButtonProps = {
  termCategory?: TermCategoryListQuery['term_category'][number]
}
export default function AddEditTermCategoryButton({ termCategory }: AddEditCountryButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      addButton={!termCategory ? t('termCategory.list.add') : undefined}
      editButton={termCategory ? t('termCategory.list.edit') : undefined}
      headline={termCategory ? t('termCategory.list.edit') : t('termCategory.list.add')}>
      <TermCategoryForm termCategory={termCategory} />
    </SidebarDrawer>
  )
}
