import {NhostReactClientConstructorParams} from "@nhost/react";

type ConfigProps = {
    nhost: NhostReactClientConstructorParams
    tssDomain: string
};
const SHOW_PRODUCTION = process.env.NODE_ENV === 'production'
    || !!process.env.NEXT_PUBLIC_DATA_LOCATION
export const CONFIG: ConfigProps = {
    nhost: {
        subdomain: SHOW_PRODUCTION ? 'hjghcghdyiaisukunhfu' : 'local',
        region: SHOW_PRODUCTION ? 'eu-central-1' : 'local'
    },
    tssDomain: SHOW_PRODUCTION ? 'https://interview.headigital.app' : 'http://localhost:3002',
};

export const UNDEFINED_UUID = '00000000-0000-0000-0000-000000000000';
