import {GridColDef} from '@mui/x-data-grid-pro'
import {useGenericQuery, UsersQuery} from '@feg/ui'
import AddEditUserButton from './AddEditUserButton'
import InviteUsersButton from './InviteUsersButton'
import DataTableContainer from '../generic/dataTable/DataTableContainer'
import {transformDate} from "@feg/ui/src/hooks/useDate";
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useDeleteUsersMutation, useUsersQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<UsersQuery['users'][number]>[] = [{
    field: 'email',
    headerName: 'Email',
    flex: 1
}, {
    field: 'name',
    headerName: 'Full Name',
    flex: 1,
    valueGetter: ({row}) => row.user_profile ? `${row.user_profile.last_name}, ${row.user_profile.first_name}` : row.displayName
}, {
    field: 'lastSeen',
    headerName: 'Last Login',
    flex: 1,
    valueGetter: ({row}) => transformDate(row.lastSeen, {dateStyle: 'medium', timeStyle: 'short'})
}, {
    field: 'emailVerified',
    headerName: 'Email Verified',
    flex: 0.5
}]

export default function UsersList() {
    const {data, isFetching, refetch} = useUsersQuery()
    const [selectedRows, setSelectedRows] = useState<UsersQuery['users']>([])
    useGenericQuery({
        isFetching, refetch, setSelectedRows
    })
    const {isPending, mutateAsync} = useDeleteUsersMutation()
    const rows = data?.users || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length > 0 && (
                        <InviteUsersButton users={selectedRows}/>
                    )}
                    {selectedRows?.length === 1 && (
                        <AddEditUserButton user={selectedRows[0]}/>
                    )}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows?.map(i => i.id)
                            })}
                            count={selectedRows?.length}
                            isLoading={isPending}
                        />
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}
