import {TextFieldElement, TextFieldElementProps} from "react-hook-form-mui";
import {forwardRef} from "react";
import {NumericFormat, NumericFormatProps} from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
            />
        );
    },
);

type TextFieldNumberFormatProps = TextFieldElementProps

export function TextFieldNumberFormat({type, ...props}: TextFieldNumberFormatProps) {
    // dont pass type because its not allowed to be number
    return (
        <TextFieldElement {...props} InputProps={{
            inputComponent: NumericFormatCustom as any
        }}/>
    )
}