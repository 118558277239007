import {Button} from '@mui/material'
import SidebarDrawer from '../generic/drawers/SidebarDrawer'
import UserForm from './UserForm'
import {UserOfList} from './usersTypings'
import AddButton from '../generic/buttons/AddButton'
import {useTranslation} from 'react-i18next'

type AddEditUserButton = { user?: UserOfList }
export default function AddEditUserButton({user}: AddEditUserButton) {
    const {t} = useTranslation()
    return (
        <SidebarDrawer
            closeOnSubmit={true}
            ToggleElement={user ? <Button color={'info'}>Edit</Button> : <AddButton>{t('user.list.add')}</AddButton>}
            headline={user ? 'Edit User' : 'Create User'}>
            <UserForm user={user}/>
        </SidebarDrawer>
    )
}
