// next
// @mui
import {styled} from '@mui/material/styles'
import {Box, Typography} from '@mui/material'
// hooks
// routes
import {PATH_DASHBOARD} from '../../../routes/paths'
// components
import MyAvatar from '../../../components/MyAvatar'
import {useUserData} from "@nhost/react";
import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter
    })
}))

// ----------------------------------------------------------------------

type Props = {
    isCollapse: boolean | undefined;
};

export default function NavbarAccount({isCollapse}: Props) {
    const user = useUserData()

    return (
        <MuiLink component={Link} to={PATH_DASHBOARD.user.account} underline={'none'}>
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: 'transparent'
                    })
                }}
            >
                <MyAvatar/>

                <Box
                    sx={{
                        ml: 2,
                        overflow: 'hidden',
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.shorter
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0
                        })
                    }}
                >
                    <Typography variant="subtitle2" noWrap>
                        {user?.displayName}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{color: 'text.secondary'}}>
                        {user?.email}
                    </Typography>
                </Box>
            </RootStyle>
        </MuiLink>
    )
}
