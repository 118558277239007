import {create} from 'zustand'
import {useShallow} from "zustand/react/shallow";

type RefetchState = {
    refetchTimestamp: string
    setRefetchTimestamp: () => void
}
export const useRefetchState = create<RefetchState>()(set => ({
    refetchTimestamp: '',
    setRefetchTimestamp: () => set({refetchTimestamp: new Date().toISOString()})
}))

export const useRefetchTimestamp = () => useRefetchState(useShallow(state => state.refetchTimestamp))
