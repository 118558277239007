import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import InterviewEdit from '../../../components/interview/InterviewEdit'
import {LoadingScreen} from "@feg/ui/src/theme/components/LoadingScreen";
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";
import {useParams} from "react-router-dom";
import {useInterviewByPkQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";


// ----------------------------------------------------------------------

export default function InterviewEditPage() {
    const {t} = useTranslation()
    const params = useParams()
    const {data, isFetching} = useInterviewByPkQuery({
        id: params.id
    })
    return (
        <>
            {isFetching && <LoadingScreen/>}
            {data?.project_interview_by_pk && (
                <PageRoot title={t('interview.detail.main')}
                          breadcrumbs={{
                              heading: t('interview.detail.main'),
                              links: [
                                  {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                                  {
                                      name: t('project.list.main'),
                                      href: PATH_DASHBOARD.general.projectList
                                  },
                                  {
                                      name: t('project.detail.main'),
                                      href: PATH_DASHBOARD.project.edit + data?.project_interview_by_pk?.project_id
                                  },
                                  {name: t('interview.detail.main')}
                              ]
                          }}>
                    <InterviewEdit interview={data.project_interview_by_pk}/>
                </PageRoot>
            )}
        </>
    )
}

