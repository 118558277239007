import { Button, ButtonProps } from '@mui/material'
import Add from '@mui/icons-material/Add'
import { FC, PropsWithChildren } from 'react'

type AddButtonProps = ButtonProps

const AddButton: FC<PropsWithChildren<AddButtonProps>> = (props) => {
  return <Button variant="contained" startIcon={<Add />} {...props} />
}

export default AddButton
