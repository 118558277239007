import {Control, UseFormSetValue} from 'react-hook-form'
import {Button, DialogActions, DialogContent, TextField} from '@mui/material'
import {useRef, useState} from 'react'
import {encryptString} from '../../lib/password/passwordHelper'
import {useTranslation} from 'react-i18next'
import DialogAnimate from "@feg/ui/src/theme/components/animate/DialogAnimate";

type PasswordChangeProps = {
    name: string
    control: Control<any>
    setValue: UseFormSetValue<any>
}
export default function PasswordChange({control, name, setValue}: PasswordChangeProps) {
    const {t} = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    const newPasswordRef = useRef<string>('')
    return (
        <>
            <Button onClick={() => setOpen(true)} sx={{
                my: 3
            }}>
                {t('changePassword')}
            </Button>
            <DialogAnimate open={open}>
                <form noValidate onSubmit={() => {
                    if (newPasswordRef.current) {
                        // prevent empty password field
                        setValue(name, encryptString(newPasswordRef.current))
                    }
                    setOpen(false)
                }}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label={t('newPassword')}
                            onChange={event => {
                                newPasswordRef.current = event.target.value
                            }}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color={'inherit'}>{t('close')}</Button>
                        <Button type={'submit'}>{t('setPassword')}</Button>
                    </DialogActions>
                </form>
            </DialogAnimate>
        </>
    )
}
