import {FC, PropsWithChildren} from 'react'
import {LoadingButton, LoadingButtonProps} from '@mui/lab'
import {IconButton, IconButtonProps} from "@mui/material";

type ConfirmButtonProps = {
    buttonProps?: LoadingButtonProps,
    iconProps?: IconButtonProps,
    message: string,
    onConfirm: () => void
}

export const ConfirmButton: FC<PropsWithChildren<ConfirmButtonProps>> = ({
                                                                             buttonProps,
                                                                             message,
                                                                             onConfirm,
                                                                             iconProps,
                                                                             children
                                                                         }) => {
    const onClick = () => {
        const confirmed = window.confirm(message)
        if (confirmed) {
            onConfirm()
        }
    }
    return (
        <>
            {buttonProps && (
                <LoadingButton {...buttonProps}
                               onClick={onClick}>
                    {children}
                </LoadingButton>
            )}
            {iconProps && (
                <IconButton {...iconProps} onClick={onClick}>{children}</IconButton>
            )}
        </>
    )
}
