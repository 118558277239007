import {useTranslation} from 'react-i18next'
import {Status_Enum, useMutationResult,} from '@feg/ui'
import {useState} from 'react'
import {Stack} from "@mui/material";
import {useSnackbar} from "notistack";
import {ConfirmButton} from "@feg/ui/src/common/buttons";
import {
    useDeleteInterviewMutation,
    useUpdateProjectInterviewSimpleMutation
} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type DeleteInterviewButtonProps = {
    id: string
    status?: Status_Enum | null
    allowDelete?: boolean
}

export default function ToggleInterviewStatus({id, status, allowDelete}: DeleteInterviewButtonProps) {
    const {t} = useTranslation()
    const [currentStatus, setStatus] = useState<Status_Enum | null | undefined>(status)
    const {error, data, isPending, mutateAsync} = useUpdateProjectInterviewSimpleMutation()
    const deleteMutation = useDeleteInterviewMutation()
    const {enqueueSnackbar} = useSnackbar()
    useMutationResult({error, data})
    useMutationResult({error: deleteMutation.error, data: deleteMutation.data})
    let isArchived = currentStatus === Status_Enum.Archived
    return (
        <Stack>
            <ConfirmButton
                buttonProps={{
                    color: isArchived ? 'warning' : 'info',
                    loading: isPending
                }}
                message={isArchived ? t('recoverData') : t('archiveInterview')}
                onConfirm={async () => {
                    try {

                        let setValue = currentStatus !== Status_Enum.Archived ? Status_Enum.Archived : null
                        await mutateAsync({
                            id,
                            set: {
                                status: setValue
                            }
                        })
                        setStatus(setValue)
                    } catch (e) {
                        console.error(e)
                        enqueueSnackbar('An error occured. Please check the console.', {variant: 'error'})
                    }
                }}>
                {isArchived ? t('recoverData') : t('archive')}
            </ConfirmButton>

            {allowDelete && (
                <ConfirmButton message={'are you sure?'}
                               onConfirm={async () => {
                                   try {
                                       await deleteMutation.mutateAsync({
                                           id
                                       })
                                       window.location.reload() // hard reload
                                   } catch (e) {
                                       console.error(e)
                                       enqueueSnackbar('An error occured. Please check the console.', {variant: 'error'})
                                   }
                               }}
                               buttonProps={{
                                   color: 'error',
                                   loading: deleteMutation.isPending
                               }}
                >Delete </ConfirmButton>
            )}
        </Stack>
    )
}
