import {ReactNode, useMemo} from 'react';
// @mui
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
// hooks
import {useSettingsState} from "../contexts/SettingsContext";
//

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function ThemeColorPresets({children}: Props) {
    const defaultTheme = useTheme();
    const {setColor} = useSettingsState();

    const themeOptions = useMemo(
        () => ({
            ...defaultTheme,
            palette: {
                ...defaultTheme.palette,
                primary: setColor,
            },
            // customShadows: {
            //     ...defaultTheme.customShadows,
            //     primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
            // },
        }),
        [setColor, defaultTheme]
    );

    const theme = createTheme(themeOptions);
    // todo checkout
    // theme.components = componentsOverride(theme);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
