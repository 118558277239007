import {styled} from "@mui/material/styles";
import {
    DASHBOARD_HEADER_DESKTOP,
    DASHBOARD_HEADER_MOBILE,
    DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
    DASHBOARD_NAVBAR_WIDTH
} from "../../config";

export const RootStyle = styled('div')(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        display: 'flex',
        minHeight: '100%',
    }
}))
export type MainStyleProps = {
    collapseClick: boolean;
};
export const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick'
})<MainStyleProps>(({collapseClick, theme}) => ({
    flexGrow: 1,
    width: '100%',
    paddingTop: DASHBOARD_HEADER_MOBILE + 24,
    // paddingBottom: DASHBOARD_HEADER_MOBILE + 24,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: DASHBOARD_HEADER_DESKTOP + 24,
        // paddingBottom: DASHBOARD_HEADER_DESKTOP + 24,
        width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter
        }),
        ...(collapseClick && {
            marginLeft: DASHBOARD_NAVBAR_COLLAPSE_WIDTH
        })
    }
}))