import {ConfirmButton} from "../buttons";

type DeleteConfirmButtonProps = {
    count: number
    isLoading: boolean
    mutateAsync: () => Promise<any>
    refetch: () => Promise<any>
}
export default function DeleteTableRowButton({
                                                 mutateAsync,
                                                 isLoading,
                                                 count,
                                                 refetch
                                             }: DeleteConfirmButtonProps) {
    // const [deleteUserMutation, mutationResult] = useMutation(DocumentNode)
    // const [isFetching] = useMutationResult(mutationResult as any)

    return (
        <ConfirmButton
            buttonProps={{
                color: 'error',
                loading: isLoading,
                size: 'small'
            }}
            onConfirm={async () => {
                await mutateAsync()
                await refetch()
            }}
            message={`Do you want to delete ${count} items?`}>DELETE</ConfirmButton>
    )
}
