import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import {useTranslation} from 'react-i18next'
import CropSeasonForm from './CropSeasonForm'
import {CropSeasonListQuery} from '@feg/ui'
import {memo} from 'react'

type AddEditMeasureButtonProps = {
    cropSeason?: CropSeasonListQuery['crop_season'][number]
}

function AddEditCropSeasonButton({cropSeason}: AddEditMeasureButtonProps) {
    const {t} = useTranslation()
    return (
        <SidebarDrawer
            addButton={!cropSeason ? t('crops.add') : undefined}
            editButton={cropSeason ? t('crops.edit') : undefined}
            headline={cropSeason ? t('crops.edit') : t('crops.add')}
        >
            <CropSeasonForm cropSeason={cropSeason}/>
        </SidebarDrawer>
    )
}

export default memo(AddEditCropSeasonButton)
