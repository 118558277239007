import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditLocationButton from './AddEditLocationButton'
import {getCountryRegionNested} from '../countryRegion/countryRegionHelper'
import {LocationQuery, useGenericQuery} from '@feg/ui'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useDeleteLocationMutation, useLocationQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<LocationQuery['location'][number]>[] = [{
    field: 'name',
    headerName: 'Name',
    flex: 1
}, {
    field: 'country_region',
    headerName: 'Region',
    flex: 1,
    valueGetter: ({row}) => {
        return `${getCountryRegionNested(row)} (${row.zone?.country?.en})`
    }
}, {
    field: 'zone_id',
    headerName: 'Zone',
    flex: 1,
    valueGetter: ({row}) => row.zone?.name
}]

export default function LocationList() {
    const {data, error, isFetching, refetch} = useLocationQuery()
    const [selectedRows, setSelectedRows] = useState<LocationQuery['location']>([])

    useGenericQuery({
        isFetching, refetch, setSelectedRows
    })
    const {mutateAsync, isPending} = useDeleteLocationMutation()
    const rows = data?.location || []
    return (
        <DataTableContainer
            ToolbarElements={
                <>
                    {selectedRows?.length === 1 && (
                        <AddEditLocationButton location={selectedRows[0]}/>
                    )}
                    {selectedRows?.length > 0 && (
                        <DeleteTableRowButton
                            refetch={refetch}
                            isLoading={isPending}
                            count={selectedRows?.length}
                            mutateAsync={() => mutateAsync({
                                ids: selectedRows.map(i => i.id)
                            })}/>
                    )}
                </>
            }
            dataTableProps={{
                loading: isFetching,
                columns: GridColums,
                rows,
                checkboxSelection: true,
                rowSelectionModel: selectedRows?.map(i => i.id),
                onRowSelectionModelChange: (selectionModel) => {
                    const modelValues = rows.filter(i => selectionModel.includes(i.id))
                    setSelectedRows(modelValues)
                }
            }}/>
    )
}
