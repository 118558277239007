import {getAllISOCodes} from 'iso-country-currency'
import {Control, Controller, ControllerProps} from 'react-hook-form'
import {Autocomplete, AutocompleteProps, TextField, TextFieldProps} from '@mui/material'
import {useTranslation} from 'react-i18next'

const codes = getAllISOCodes()
    .map(i => i.currency)
    .filter((v, i, a) => a.indexOf(v) === i)
codes.unshift('') // add empty

type CurrencySelectorProps<K> = Pick<ControllerProps, 'name' | 'rules'> & TextFieldProps & {
    control: Control<any>
    autocompleteProps?: Pick<AutocompleteProps<K, any, any, any>, 'getOptionLabel' | 'multiple' | 'fullWidth'>
}

export default function CurrencySelector<T>({
                                                name,
                                                control,
                                                autocompleteProps,
                                                ...textFieldProps
                                            }: CurrencySelectorProps<T>) {
    const {t} = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState, formState}) => {
                const curVal = typeof value === 'string'
                    ? codes.find((i) => i === value)
                    : value
                return (
                    <Autocomplete
                        {...autocompleteProps}
                        value={curVal || ''}
                        disableClearable
                        getOptionLabel={(option) => {
                            const opts =
                                typeof option === 'string'
                                    ? option
                                    : `${option.currency} (${option.countryName})`
                            return opts
                        }}
                        onChange={(_event, v) => {
                            onChange(v)
                        }}

                        renderInput={params => {
                            return (
                                // @ts-ignore
                                <TextField
                                    name={name}
                                    {...textFieldProps}
                                    {...params}
                                    label={t('project.form.currency')}/>
                            )
                        }}
                        options={codes}
                    />
                )
            }}
        />
    )
}
