import {useState} from 'react';
// next
// @mui
import {Collapse, List} from '@mui/material';
//
import {NavItemRoot, NavItemSub} from './NavItem';
import {NavListProps} from './type';
import {useLocation} from "react-router";

// ----------------------------------------------------------------------

const getActive = (path: string, pathname: string,) =>
    pathname.includes(path);

type NavListRootProps = {
    list: NavListProps;
    isCollapse: boolean;
};

export function NavListRoot({list, isCollapse}: NavListRootProps) {
    const {pathname} = useLocation();

    const active = getActive(list.path, pathname);

    const [open, setOpen] = useState(active);

    const hasChildren = list.children;

    if (hasChildren) {
        return (
            <>
                <NavItemRoot
                    item={list}
                    isCollapse={isCollapse}
                    active={active}
                    open={open}
                    onOpen={() => setOpen(!open)}
                />

                {!isCollapse && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(list.children || []).map((item) => (
                                <NavListSub key={item.title} list={item}/>
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    }

    return <NavItemRoot item={list} active={active} isCollapse={isCollapse}/>;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
    list: NavListProps;
};

function NavListSub({list}: NavListSubProps) {
    const {pathname} = useLocation();

    const activeRoot = getActive(list.path, pathname);

    const [open, setOpen] = useState(activeRoot);

    const hasChildren = list.children;

    if (hasChildren) {
        return (
            <>
                <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={activeRoot}/>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{
                        pl: 3,
                        '& a': {
                            textDecoration: 'none'
                        }
                    }}>
                        {(list.children || []).map((item) => (
                            <NavItemSub
                                key={item.title}
                                item={item}
                                active={getActive(item.path, pathname)}
                            />
                        ))}
                    </List>
                </Collapse>
            </>
        );
    }

    return <NavItemSub item={list} active={activeRoot}/>;
}
