import {useRefetchTimestamp} from '../lib'
import {useEffect, useState} from 'react'


export function useGenericQuery<T extends () => void, B, C extends (a: any[]) => void>({
                                                                                           refetch,
                                                                                           isFetching,
                                                                                           setSelectedRows
                                                                                       }: {
    refetch: T
    isFetching: B
    setSelectedRows: C
}) {


    // const {data, loading, refetch, called} = useQuery<T>(props.query)
    const refetchTimestamp = useRefetchTimestamp()
    const [refetchTimestampState, setRefetchTimestampState] = useState<string | null>(null)
    useEffect(() => {
        const refetchAsync = async () => {
            await refetch()
            setRefetchTimestampState(refetchTimestamp)
        }
        if (refetchTimestamp && !isFetching && refetchTimestampState !== refetchTimestamp) {
            // console.log('timestamp adjusted need to refetch', refetchTimestamp)
            setSelectedRows([])
            refetchAsync()
        }

    }, [refetchTimestamp, refetch, isFetching, setSelectedRows, refetchTimestampState])
}
