// routes
import {PATH_DASHBOARD} from '../../../routes/paths'
// components
import SvgIconStyle from '../../../components/SvgIconStyle'
import {useTranslation} from 'react-i18next'
import PinDrop from '@mui/icons-material/PinDrop'
import AccountTree from '@mui/icons-material/AccountTree'
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
    <SvgIconStyle src={`/icons/${name}.svg`} sx={{width: 1, height: 1}}/>
)

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking')
}

export default function useNavConfig() {
    const {t} = useTranslation('translation')

    return [
        // GENERAL
        // ----------------------------------------------------------------------
        {
            subheader: t('layout.drawer.general'),
            items: [
                {
                    title: t('layout.drawer.app'),
                    path: PATH_DASHBOARD.general.app,
                    icon: ICONS.dashboard
                },
                {
                    title: t('layout.drawer.projectList'),
                    path: PATH_DASHBOARD.general.projectList,
                    icon: <AccountTree/>
                },
            ]
        },

        // MANAGEMENT
        // ----------------------------------------------------------------------
        {
            subheader: t('layout.drawer.management'),
            items: [
                // MANAGEMENT : USER
                {
                    title: t('layout.drawer.user'),
                    path: PATH_DASHBOARD.user.root,
                    icon: ICONS.user,
                    children: [
                        {
                            title: t('layout.drawer.userlist'),
                            path: PATH_DASHBOARD.user.list
                        }
                    ]
                },
                {
                    title: t('layout.drawer.location'),
                    path: PATH_DASHBOARD.location.root,
                    icon: <PinDrop/>,
                    children: [
                        {
                            title: t('layout.drawer.country'),
                            path: PATH_DASHBOARD.location.countryList
                        },
                        {
                            title: t('layout.drawer.countryDistrict'),
                            path: PATH_DASHBOARD.location.countryRegion
                        },
                        {
                            title: t('layout.drawer.zone'),
                            path: PATH_DASHBOARD.location.zoneList
                        },
                        {
                            title: t('layout.drawer.location'),
                            path: PATH_DASHBOARD.location.list
                        },
                        {
                            title: t('layout.drawer.population'),
                            path: PATH_DASHBOARD.location.population
                        }
                    ]
                },
                {
                    title: t('layout.drawer.admin'),
                    path: PATH_DASHBOARD.admin.root,
                    icon: ICONS.banking,
                    children: [
                        {
                            title: t('layout.drawer.wealthGroup'),
                            path: PATH_DASHBOARD.admin.wealthGroupList
                        },
                        {
                            title: t('layout.drawer.termTaxonomy'),
                            path: PATH_DASHBOARD.admin.termCategory
                        },
                        {
                            title: t('layout.drawer.term'),
                            path: PATH_DASHBOARD.admin.term
                        },
                        {
                            title: t('layout.drawer.measure'),
                            path: PATH_DASHBOARD.admin.measure
                        },
                        {
                            title: t('layout.drawer.crops'),
                            path: PATH_DASHBOARD.admin.crops
                        }
                    ]
                }
            ]
        }
    ]
}
