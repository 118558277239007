import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import WealthGroupList from '../../../components/admin/wealthGroup/WealthGroupList'
import AddEditWealthGroupButton from '../../../components/admin/wealthGroup/AddEditWealthGroupButton'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function WealthGroupPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('wealthGroup.list.main')}
                  breadcrumbs={{
                      heading: t('wealthGroup.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('wealthGroup.list.main'), href: PATH_DASHBOARD.admin.wealthGroupList}
                      ],
                      action: (
                          <AddEditWealthGroupButton/>
                      )
                  }}>
            <WealthGroupList/>
        </PageRoot>
    )
}


