import {ReactNode, useRef} from 'react';
import {IconifyIcon} from '@iconify/react';
import {SnackbarKey, SnackbarProvider} from 'notistack';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import {Box, GlobalStyles} from '@mui/material';
// theme
import {ColorSchema} from '../theme/palette';
//
import Iconify from './Iconify';
import IconButtonAnimate from "./animate/IconButtonAnimate";
import {CheckCircleOutlined, Clear, Error, Info, Warning} from "@mui/icons-material";

// ----------------------------------------------------------------------

function SnackbarStyles() {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    return (
        <GlobalStyles
            styles={{
                'body': {
                    '& .SnackbarContent-root': {
                        width: '100%',
                        padding: theme.spacing(1),
                        margin: theme.spacing(0.25, 0),
                        boxShadow: theme.customShadows.z8,
                        borderRadius: theme.shape.borderRadius,
                        color: theme.palette.grey[isLight ? 0 : 800],
                        backgroundColor: theme.palette.grey[isLight ? 900 : 0],
                        '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
                            {
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.paper,
                            },
                        [theme.breakpoints.up('md')]: {
                            minWidth: 240,
                        },
                    },
                    '& .SnackbarItem-message': {
                        padding: '0 !important',
                        fontWeight: theme.typography.fontWeightMedium,
                    },
                    '& .SnackbarItem-action': {
                        marginRight: 0,
                        color: theme.palette.action.active,
                        '& svg': {width: 20, height: 20},
                    },
                },
            }}
        />
    );
}

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function NotistackProvider({children}: Props) {
    const notistackRef = useRef<any>(null);

    const onClose = (key: SnackbarKey) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <>
            <SnackbarStyles/>

            <SnackbarProvider
                ref={notistackRef}
                dense
                maxSnack={5}
                preventDuplicate
                autoHideDuration={3000}
                variant="success" // Set default variant
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                iconVariant={{
                    info: <Info/>,
                    success: <CheckCircleOutlined/>,
                    warning: <Warning/>,
                    error: <Error/>,
                }}
                // With close as default
                action={(key) => (
                    <IconButtonAnimate size="small" onClick={onClose(key)} sx={{p: 0.5}}>
                        <Clear color={'inherit'}/>
                    </IconButtonAnimate>
                )}
            >
                {children}
            </SnackbarProvider>
        </>
    );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
    icon: IconifyIcon | string;
    color: ColorSchema;
};

function SnackbarIcon({icon, color}: SnackbarIconProps) {
    return (
        <Box
            component="span"
            sx={{
                mr: 1.5,
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
                color: `${color}.main`,
                bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
            }}
        >
            <Iconify icon={icon} width={24} height={24}/>
        </Box>
    );
}
