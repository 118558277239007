import {Box, Container, Typography} from '@mui/material'
import {PropsWithChildren} from 'react'
import HeaderBreadcrumbs, {Props} from '../../theme/components/HeaderBreadcrumbs'
import {Page} from "../../theme/components/Page";
import {useSettingsState} from "../../theme/contexts/SettingsContext";

type PageRootProps = {
    title: string
    breadcrumbs?: Props
}

export function PageRoot({
                             breadcrumbs,
                             title,
                             children
                         }: PropsWithChildren<PageRootProps>) {
    const {themeStretch} = useSettingsState()
    return (
        <>
            <Page title={title}>
                <Container maxWidth={themeStretch ? false : 'xl'}
                           sx={{minHeight: [null, 'calc(100vh - 150px)', 'calc(100vh - 180px)']}}>
                    {breadcrumbs && <HeaderBreadcrumbs {...breadcrumbs} />}
                    {children}
                </Container>
                <Box component={'footer'} paddingY={'16px'}>
                    <Container maxWidth={themeStretch ? false : 'xl'}>
                        <Typography align={'center'}>
                            &copy; {new Date().getFullYear()} Food Economy Group
                        </Typography>
                    </Container>
                </Box>
            </Page>
        </>
    )
}

