import {
    CountryListQuery,
    Measure_Unit_Type_Enum,
    MeasureListQuery,
    UpsertMeasureMutation,
    useGenericMutation,
    useMutationResult
} from '@feg/ui'
import {SelectElement, TextFieldElement} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {TextFieldNumberFormat} from "@feg/ui/src/common/form/TextFieldNumberFormat";
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {useCountryListQuery, useUpsertMeasureMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";

const schema = {
    name: '',
    type: '',
    multiplier: '',
    country_id: ''
}

type Schema = typeof schema

type MeasureFormProps = {
    measureUnit?: MeasureListQuery['measure_unit'][number]
}

export default function MeasureForm({measureUnit}: MeasureFormProps) {
    const {t} = useTranslation()
    const {data, error, isPending: isFetching, mutateAsync} = useUpsertMeasureMutation()
    const {
        control,
        onSubmit,
    } = useGenericMutation<Schema, UpsertMeasureMutation, typeof mutateAsync>({
        id: measureUnit?.id,
        mutateAsync,
        gqlSchemaName: 'insert_measure_unit_one',
        defaultValues: {
            name: measureUnit?.name,
            type: measureUnit?.type,
            multiplier: measureUnit?.multiplier,
            country_id: measureUnit?.country_id
        },
    })
    useMutationResult({data, error})
    const options = Object.keys(Measure_Unit_Type_Enum).map(k => ({
        // @ts-ignore
        id: Measure_Unit_Type_Enum[k],
        // @ts-ignore
        label: t(`measureUnit.${Measure_Unit_Type_Enum[k]}`)
    }))
    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <TextFieldElement name={'name'} label={t('measure.form.name')} control={control}/>
                <SelectElement name={'type'} label={t('measure.form.type')} control={control} options={options}/>
                <TextFieldNumberFormat name={'multiplier'} label={t('measure.form.multiplier')}
                                       control={control as any}/>
                <AutocompleteFetchQuery name={'country_id'}
                                        label={t('country.name')}
                                        queryKey={useCountryListQuery.getKey()}
                                        asyncQuery={useCountryListQuery.fetcher()}
                                        gqlSchema={'country'}
                                        control={control}
                                        autocompleteProps={{
                                            getOptionLabel: (option: CountryListQuery['country'][number] | string) => {
                                                if (typeof option === 'string') return option
                                                if (!option) return ''
                                                return option.en
                                            }
                                        }}
                />
                <SubmitButton loading={isFetching}/>
            </FormStack>
        </form>
    )
}
