import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import ProjectEdit from '../../../components/project/ProjectEdit'
import {LoadingScreen} from "@feg/ui/src/theme/components/LoadingScreen";
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";
import {useParams} from "react-router-dom";
import {useProjectByPkQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";


export default function ProjectEditPage() {
    const {t} = useTranslation()
    const params = useParams()
    const {data, isFetching, error} = useProjectByPkQuery({
        id: params.id
    })
    if (error) {
        console.log(error)
    }
    return (
        <PageRoot title={t('project.detail.main')}
                  breadcrumbs={{
                      heading: t('project.detail.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('layout.drawer.projectList'), href: PATH_DASHBOARD.general.projectList},
                          {name: t('project.detail.edit')}
                      ]
                  }}>
            {isFetching && <LoadingScreen/>}
            {data?.project_by_pk && <ProjectEdit project={data.project_by_pk}/>}
        </PageRoot>
    )
}


