import {useUserId} from "@nhost/react"
import {createStore, UseStore} from "idb-keyval"
import produce from 'immer'
import pick from "lodash/pick"
import {create} from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {getMonthsOnDays, MONTHS} from "../../hooks/useMonthOptions"
import {useInterviewContext} from "../../tss/InterviewProvider"
import {Month_Enum} from "../gql/__codegen__/react-query"
import {getIdbStorage,} from "./idbStorage"
import {TssState, TssVillageData} from "./tssTypes"
import {useShallow} from "zustand/react/shallow";

const tssDb = typeof indexedDB !== "undefined" && createStore("tssDb", "tss")

const tssIdbStorage = getIdbStorage(tssDb as UseStore)


export const villageBaseDataKeys: (keyof TssVillageData)[] = ['wealth_group', 'date', 'id', 'project_interview_researcher_mns', 'project_id', 'location'];

export const useTssState = create<TssState>()(devtools(persist((set, get) => ({
    projects: [],
    villages: [],
    updatedAt: {},
    setUploadedTimestamp: (villageId, timestamp) => set(produce<TssState>(state => {
        const indexOfInterview = state.villages.findIndex(i => i.id === villageId)
        if (indexOfInterview > -1) {
            state.villages[indexOfInterview].created_at = timestamp
        }
    })),
    unsetUpdatedAt: (villageId) => set(produce<TssState>(state => {
        state.updatedAt = Object.keys(state.updatedAt)
            .filter(key => key !== villageId)
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: state.updatedAt[key]
                }
            }, {})
    })),
    setLastUserId: (lastUserId) => set({lastUserId}),
    setInitial: (villages) => set({villages: villages}),
    resetState: () => set({projects: [], villages: [], updatedAt: {}}),
    resetProjectData: (projectId) => set(produce<TssState>(state => {
        const villateIds = state.projects.find(p => p.id === projectId)?.project_interviews.map(i => i.id) ?? []
        villateIds.forEach(villageId => {
            state.updatedAt = Object.keys(state.updatedAt)
                .filter(key => key !== villageId)
                .reduce((obj, key) => {
                    return {
                        ...obj,
                        [key]: state.updatedAt[key]
                    }
                }, {})
            state.villages = state.villages.filter(v => v.id !== villageId)
        })
        state.projects = state.projects.filter(p => p.id !== projectId)
    })),
    setProjectData: (data) => set(() => {
        const currentProjects = [...get().projects]
        const projectIndex = currentProjects.findIndex(p => p.id === data.id);
        if (projectIndex > -1) {
            // update
            currentProjects[projectIndex] = data
        } else {
            // set
            currentProjects[currentProjects.length] = data
        }
        return {
            projects: currentProjects
        }
    }),
    resetVillageData: (villageId) => set(produce<TssState>(state => {
        const villages = get().villages
        const villageIndex = villages.findIndex(village => village.id === villageId)
        if (villageIndex > -1) {
            state.villages[villageIndex] = pick(state.villages[villageIndex] as TssVillageData, villageBaseDataKeys) as TssVillageData
        }
        // state.updatedAt[villageId] = new Date().toISOString()
    })),
    setVillageData: (villageId, data, ignoreUpdatedAt) => set(produce<TssState>(state => {
        const villages = get().villages
        const villageIndex = villages.findIndex(village => village.id === villageId)
        if (villageIndex > -1) {
            const old = villages[villageIndex]
            const updated = {
                ...old,
                ...data
            };
            state.villages[villageIndex] = updated
        } else {
            state.villages.push({
                id: villageId,
                ...data
            })
        }
        if (!ignoreUpdatedAt) {
            state.updatedAt[villageId] = new Date().toISOString()
        }
    }))
}), {
    name: 'tss-storage',
    version: 1.0,
    storage: createJSONStorage(() => tssIdbStorage)
})))


export const useUnsetTssUpdatedAt = () => {
    const set = useTssState(useShallow(state => state.unsetUpdatedAt))
    return (interviewId: string) => set(interviewId)
}
export const useTssUpdatedAt = (interviewId: string) => {
    const state = useTssState(useShallow(state => state.updatedAt))
    return state[interviewId]
}
export const useTssSetLastUserId = () => useTssState(useShallow(state => state.setLastUserId))
export const useTssResetVillageData = () => useTssState(useShallow(state => state.resetVillageData))

export const useTssProjects = () => {
    const [projects, lastUserId] = useTssState(useShallow(state => [state.projects, state.lastUserId]))
    // filter only projects which are relevant for the userId
    return projects
        .filter(project => {
            // todo check admin account
            if (project.user?.id === lastUserId) {
                return true
            }
            if (project.userTeamleader?.id === lastUserId) {
                return true
            }
            // todo check interview level
            const researcherIds: string[] = []
            project.project_interviews.forEach(interview => {
                interview.project_interview_researcher_mns.forEach(res => {
                    const usrId = res.user?.id;
                    if (usrId) {
                        researcherIds.push(usrId)
                    }
                })
            })
            return lastUserId && researcherIds.includes(lastUserId);
        })
}

export const useUserRoleInInterview = (interviewId: string): 'teamleader' | 'supervisor' | 'researcher' | null => {
    const userId = useUserId()
    const {project, village} = useProjectAndInterview(interviewId)
    if (project?.user?.id === userId) {
        return 'supervisor'
    } else if (project?.userTeamleader?.id === userId) {
        return 'teamleader'
    } else if (village?.project_interview_researcher_mns?.find(i => i.user.id === userId)) {
        return 'researcher'
    }
    return null
}
export const useUserRoleInProject = (projectId: string): 'teamleader' | 'supervisor' | 'researcher' | null => {
    const userId = useUserId()
    const [projects] = useTssState(useShallow(state => [state.projects]))
    const project = projects.find(i => i.id === projectId)
    if (project) {
        if (project?.user?.id === userId) {
            return 'supervisor'
        } else if (project?.userTeamleader?.id === userId) {
            return 'teamleader'
        } else if (project?.project_interviews?.find(i => i.project_interview_researcher_mns?.some(i => i.user?.id === userId))) {
            return 'researcher'
        }
    }
    console.warn(`project with ID ${projectId} not found.`)
    return null
}

export const useTssVillageById = (id: string) => {
    const villages = useTssState(useShallow(state => state.villages))
    return villages.find(i => i.id === id)
}

export const useSetVillageData = () => useTssState(useShallow(state => state.setVillageData))
export const useTssSetUploadedTimestamp = () => useTssState(useShallow(state => state.setUploadedTimestamp))

export const getProjectMonths = (interviewId: string): null | undefined | Month_Enum[] => {
    const projects = useTssState.getState().projects;
    const project = projects.find(i => i.project_interviews.find(z => z.id === interviewId))
    if (project) {
        if (project.reference_period_start && project.reference_period_duration) {
            const start = MONTHS[new Date(project.reference_period_start).getMonth()]
            const monthsStartEnd = getMonthsOnDays(start, project.reference_period_duration, 10)
            return monthsStartEnd?.months?.map(i => i.month)
        }
    }
    return null
}

export const useInterviewCountryId = () => {
    const {countryId} = useInterviewContext()
    return countryId
}

export const useProjectAndInterview = (interviewId: string) => {
    const [villages, projects] = useTssState(useShallow(state => [state.villages, state.projects]))
    const village = villages.find(i => i.id === interviewId)
    if (!village) {
        // console.warn(`interview with ID ${interviewId} not found.`)
    }
    const project = projects.find(i => i.id === village?.project_id)
    if (!project) {
        // console.warn(`project with ID ${village?.project_id} not found.`)
    }
    return {village, project}
}

export const useOnlineInterviewData = () => {
    const {projectId, id: interviewId} = useInterviewContext()
    const [projects] = useTssState(useShallow(state => [state.projects]))
    const interview = projects.find(i => i.id === projectId)
        ?.project_interviews?.find(i => i.id === interviewId)
    return {
        projectId: projectId,
        interviewId: interview?.id,
        interviewStatus: interview?.status,
        ...interview?.project_interview_base,
        interview
    }
}

export const useHouseholdSizeById = (id: string) => {
    const {village} = useProjectAndInterview(id)
    return village?.baseData?.people_in_household ?? 0
}

export const calculateHouseholdNeeds = ({
                                            kcalPppd,
                                            hhMember,
                                            durationDays
                                        }: { kcalPppd?: number, hhMember: number, durationDays?: number | null }) =>
    (hhMember || 0) * (kcalPppd || 2100) * (durationDays || 365)

export const getHouseholdNeedOnInterviewId = (interviewId: string) => {
    const p = useTssState.getState()
    const {projects, villages} = p
    const village = villages.find(i => i.id === interviewId)
    if (!village) {
        console.warn(`interview with ID ${interviewId} not found.`)
    }
    const project = projects.find(i => i.id === village?.project_id)
    if (!project) {
        console.warn(`project with ID ${village?.project_id} not found.`)
    }
    const hhSize = village?.baseData?.people_in_household ?? 0
    if (hhSize) {
        return calculateHouseholdNeeds({
            kcalPppd: project?.kcal_pppd,
            durationDays: project?.reference_period_duration,
            hhMember: hhSize
        })
    } else {
        console.warn('there is no household size in the setup of the interview')
    }
    return 0
}


export const useHouseholdNeedsById = (id: string) => {
    const {village, project} = useProjectAndInterview(id)
    const hhSize = village?.baseData?.people_in_household ?? 0
    return calculateHouseholdNeeds({
        kcalPppd: project?.kcal_pppd,
        durationDays: project?.reference_period_duration,
        hhMember: hhSize
    })
}

export const useCalculateHouseholdNeedsById = (id: string) => {
    const hhNeeds = useHouseholdNeedsById(id)
    const calculateNeed = (value: number) => {
        return {
            need: hhNeeds,
            percent: (value / hhNeeds) * 100
        }
    }
    return calculateNeed
}

type CalculateProportionalKcal = {
    householdMember?: number
    numberWeeks: number
    mealsDay?: number
    isSnack?: boolean
    timesPerWeek?: number
};
export const useProportionalKcalById = (id: string) => {
    const {village, project} = useProjectAndInterview(id)
    const durationWeeks = Math.round((project?.reference_period_duration || 365) / 7)
    const hhSize = village?.baseData?.people_in_household ?? 0
    const calculateNeed = ({
                               householdMember, isSnack = false, mealsDay = 1, numberWeeks, timesPerWeek = 7,
                           }: CalculateProportionalKcal) =>
        (((householdMember || hhSize) / hhSize) * (numberWeeks / durationWeeks) * (mealsDay / 3) * (timesPerWeek / 7) * (isSnack ? 0.5 : 1)) * 100

    return calculateNeed
}


export const calculateSchoolFeedKcal = (amountChildren: number, {
    hhSize = 0, schoolDays = 200, refDuration = 365, kcal = 450, kcalTotal = 2100
}) => {
    return (amountChildren / hhSize) * (schoolDays / refDuration) * (kcal / kcalTotal) * 100
}
export const useSchoolFeedKcal = () => {
    const {id} = useInterviewContext()
    const {village, project} = useProjectAndInterview(id)
    const kcal = project?.school_food_kcal ?? 450
    const schoolDays = project?.schooldays_year ?? 200
    const hhSize = village?.baseData?.people_in_household ?? 0
    const refDuration = project?.reference_period_duration ?? 365
    const kcalTotal = project?.kcal_pppd ?? 2100
    const calculate = (amountChildren: number) => calculateSchoolFeedKcal(amountChildren, {
        hhSize, schoolDays, refDuration, kcal, kcalTotal
    })
    return calculate
}
