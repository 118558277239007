import {LmTiptapRichtext} from "./TipTap";
import {Controller, FieldValues} from "react-hook-form";
import {LmTipTapRichtextRhfProps} from "./richtTextTypes";


export function LmTipTapRichtextRhf<T extends FieldValues>({
                                                               name,
                                                               control,
                                                               rules = {},
                                                               defaultValue,
                                                               ...inputProps
                                                           }: LmTipTapRichtextRhfProps<T>) {
    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            defaultValue={defaultValue}
            render={({field: {onChange, value}}) => (
                <LmTiptapRichtext
                    {...inputProps}
                    onChange={(text) => {
                        onChange(text)
                        if (typeof inputProps.onChange === 'function') {
                            inputProps.onChange(text)
                        }
                    }}
                    value={value}
                />
            )}
        />
    )
}