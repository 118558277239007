import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import LocationForm from './LocationForm'
import { LocationQuery } from '@feg/ui'

type AddEditLocationButtonProps = {
  location?: LocationQuery['location'][number]
}
export default function AddEditLocationButton({ location }: AddEditLocationButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      addButton={!location ? t('location.list.add') : undefined}
      editButton={location ? t('location.list.edit') : undefined}
      headline={location ? t('location.list.edit') : t('location.list.add')}
      closeOnSubmit>
      <LocationForm location={location} />
    </SidebarDrawer>
  )
}
