import {useTranslation} from 'react-i18next'
// @mui
import {amET, enUS, esES, frFR, ptPT} from '@mui/material/locale'
import gbSvg from '../assets/locales/gb.svg'
import esSvg from '../assets/locales/es.svg'
import frSvg from '../assets/locales/fr.svg'
import ptSvg from '../assets/locales/pt.svg'

// ----------------------------------------------------------------------

const svgs = {
    gb: gbSvg,
    es: esSvg,
    fr: frSvg,
    pt: ptSvg
}


export function useLocales() {
    const {i18n} = useTranslation()
    // const {push, pathname} = useAppRouter()
    const locale = i18n.language
    const {t: translate} = useTranslation()
    const LANGS = [
        {
            label: 'English',
            value: 'en',
            systemValue: enUS,
            icon: svgs.gb
        },
        {
            label: 'French',
            value: 'fr',
            systemValue: frFR,
            icon: svgs.fr
        },
        {
            label: 'Spain',
            value: 'es',
            systemValue: esES,
            icon: svgs.es
        },
        {
            label: 'Portuguese',
            value: 'pt',
            systemValue: ptPT,
            icon: svgs.pt
        }, {
            label: 'Amharic',
            value: 'am',
            systemValue: amET
        }
    ]
    const currentLang = LANGS.find((_lang) => _lang.value === locale) || LANGS[0]

    const handleChangeLanguage = async (newlang: string) => {
        await i18n.changeLanguage(newlang) // todo need to persist it somehow?
    }

    return {
        onChangeLang: handleChangeLanguage,
        translate,
        currentLang,
        allLang: LANGS
    }
}
