import { Stack, StackProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

type FormStackProps = StackProps

export const FormStack: FC<PropsWithChildren<FormStackProps>> = ({
  children,
  direction,
  ...props
}) => {
  return (
    <Stack direction={direction || 'column'}
           gap={2}
           alignItems={direction === 'row' ? 'flex-end' : undefined}
           {...props}
    >
      {children}
    </Stack>
  )
}

