import {PATH_DASHBOARD} from '@feg/ui'
import {useTranslation} from 'react-i18next'
import CountryList from '../../../components/admin/country/CountryList'
import AddEditCountryButton from '../../../components/admin/country/AddEditCountryButton'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";

export default function CountryPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('country.list.main')}
                  breadcrumbs={{
                      heading: t('country.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('country.list.main'), href: PATH_DASHBOARD.location.countryList}
                      ],
                      action: (
                          <AddEditCountryButton/>
                      )
                  }}>
            <CountryList/>
        </PageRoot>
    )
}

