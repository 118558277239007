import {getI18n, useTranslation} from 'react-i18next'

export const transformDate = (str: Date | string, options?: Intl.DateTimeFormatOptions) => {
    const i18n = getI18n()
    if (!str) {
        return ''
    }
    const date = typeof str === 'string' ? new Date(Date.parse(str)) : str

    return new Intl.DateTimeFormat(i18n?.language || 'en', {
        ...options,
        // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(date as Date);
}

export const useDate = () => {
    const {i18n} = useTranslation()
    const lang = i18n.language
    const offset = new Date().getTimezoneOffset() * 60000
    const transformDate = (str: Date | string, options?: Intl.DateTimeFormatOptions) => {
        if (!str) {
            return ''
        }
        const date = typeof str === 'string' ? new Date(Date.parse(str) - offset) : str

        return new Intl.DateTimeFormat(lang, {
            ...options,
            // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }).format(date as Date);
    }
    const getDate = (str: Date | string, options?: Intl.DateTimeFormatOptions) => {
        return new Intl.DateTimeFormat(lang, options).format(typeof str === 'string' ? new Date(str) : str);
    }

    return {
        getDate,
        transformDate
    }
}
