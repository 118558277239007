import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import {useTranslation} from 'react-i18next'
import CountryForm from './CountryForm'
import {CountryListQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type AddEditCountryButtonProps = {
    country?: CountryListQuery['country'][number]
}
export default function AddEditCountryButton({country}: AddEditCountryButtonProps) {
    const {t} = useTranslation()
    return (
        <SidebarDrawer
            addButton={!country ? t('country.list.add') : undefined}
            editButton={country ? t('country.list.edit') : undefined}
            headline={country ? t('country.list.edit') : t('country.list.add')}>
            <CountryForm country={country}/>
        </SidebarDrawer>
    )
}
