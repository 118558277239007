export const countriesPt = [{ 'id': 4, 'name': 'Afeganistão', 'alpha2': 'af', 'alpha3': 'afg' },
  { 'id': 710, 'name': 'África do Sul', 'alpha2': 'za', 'alpha3': 'zaf' },
  { 'id': 8, 'name': 'Albânia', 'alpha2': 'al', 'alpha3': 'alb' },
  { 'id': 276, 'name': 'Alemanha', 'alpha2': 'de', 'alpha3': 'deu' },
  { 'id': 20, 'name': 'Andorra', 'alpha2': 'ad', 'alpha3': 'and' },
  { 'id': 24, 'name': 'Angola', 'alpha2': 'ao', 'alpha3': 'ago' },
  { 'id': 28, 'name': 'Antígua e Barbuda', 'alpha2': 'ag', 'alpha3': 'atg' },
  { 'id': 682, 'name': 'Arábia Saudita', 'alpha2': 'sa', 'alpha3': 'sau' },
  { 'id': 12, 'name': 'Argélia', 'alpha2': 'dz', 'alpha3': 'dza' },
  { 'id': 32, 'name': 'Argentina', 'alpha2': 'ar', 'alpha3': 'arg' },
  { 'id': 51, 'name': 'Armênia', 'alpha2': 'am', 'alpha3': 'arm' },
  { 'id': 36, 'name': 'Austrália', 'alpha2': 'au', 'alpha3': 'aus' },
  { 'id': 40, 'name': 'Áustria', 'alpha2': 'at', 'alpha3': 'aut' },
  { 'id': 31, 'name': 'Azerbaijão', 'alpha2': 'az', 'alpha3': 'aze' },
  { 'id': 44, 'name': 'Bahamas', 'alpha2': 'bs', 'alpha3': 'bhs' },
  { 'id': 50, 'name': 'Bangladexe', 'alpha2': 'bd', 'alpha3': 'bgd' },
  { 'id': 52, 'name': 'Barbados', 'alpha2': 'bb', 'alpha3': 'brb' },
  { 'id': 48, 'name': 'Barém', 'alpha2': 'bh', 'alpha3': 'bhr' },
  { 'id': 56, 'name': 'Bélgica', 'alpha2': 'be', 'alpha3': 'bel' },
  { 'id': 84, 'name': 'Belize', 'alpha2': 'bz', 'alpha3': 'blz' },
  { 'id': 204, 'name': 'Benim', 'alpha2': 'bj', 'alpha3': 'ben' },
  { 'id': 112, 'name': 'Bielorrússia', 'alpha2': 'by', 'alpha3': 'blr' },
  { 'id': 68, 'name': 'Bolívia', 'alpha2': 'bo', 'alpha3': 'bol' },
  { 'id': 70, 'name': 'Bósnia e Herzegovina', 'alpha2': 'ba', 'alpha3': 'bih' },
  { 'id': 72, 'name': 'Botsuana', 'alpha2': 'bw', 'alpha3': 'bwa' },
  { 'id': 76, 'name': 'Brasil', 'alpha2': 'br', 'alpha3': 'bra' },
  { 'id': 96, 'name': 'Brunei', 'alpha2': 'bn', 'alpha3': 'brn' },
  { 'id': 100, 'name': 'Bulgária', 'alpha2': 'bg', 'alpha3': 'bgr' },
  { 'id': 854, 'name': 'Burquina Fasso', 'alpha2': 'bf', 'alpha3': 'bfa' },
  { 'id': 108, 'name': 'Burundi', 'alpha2': 'bi', 'alpha3': 'bdi' },
  { 'id': 64, 'name': 'Butão', 'alpha2': 'bt', 'alpha3': 'btn' },
  { 'id': 132, 'name': 'Cabo Verde', 'alpha2': 'cv', 'alpha3': 'cpv' },
  { 'id': 116, 'name': 'Camboja', 'alpha2': 'kh', 'alpha3': 'khm' },
  { 'id': 120, 'name': 'Camarões', 'alpha2': 'cm', 'alpha3': 'cmr' },
  { 'id': 124, 'name': 'Canadá', 'alpha2': 'ca', 'alpha3': 'can' },
  { 'id': 634, 'name': 'Catar', 'alpha2': 'qa', 'alpha3': 'qat' },
  { 'id': 398, 'name': 'Cazaquistão', 'alpha2': 'kz', 'alpha3': 'kaz' },
  { 'id': 140, 'name': 'República Centro-Africana', 'alpha2': 'cf', 'alpha3': 'caf' },
  { 'id': 148, 'name': 'Chade', 'alpha2': 'td', 'alpha3': 'tcd' },
  { 'id': 203, 'name': 'Chéquia', 'alpha2': 'cz', 'alpha3': 'cze' },
  { 'id': 152, 'name': 'Chile', 'alpha2': 'cl', 'alpha3': 'chl' },
  { 'id': 156, 'name': 'China', 'alpha2': 'cn', 'alpha3': 'chn' },
  { 'id': 196, 'name': 'Chipre', 'alpha2': 'cy', 'alpha3': 'cyp' },
  { 'id': 170, 'name': 'Colômbia', 'alpha2': 'co', 'alpha3': 'col' },
  { 'id': 174, 'name': 'Comores', 'alpha2': 'km', 'alpha3': 'com' },
  { 'id': 178, 'name': 'República do Congo', 'alpha2': 'cg', 'alpha3': 'cog' },
  { 'id': 180, 'name': 'República Democrática do Congo', 'alpha2': 'cd', 'alpha3': 'cod' },
  { 'id': 410, 'name': 'Coreia do Sul', 'alpha2': 'kr', 'alpha3': 'kor' },
  { 'id': 408, 'name': 'Coreia do Norte', 'alpha2': 'kp', 'alpha3': 'prk' },
  { 'id': 384, 'name': 'Costa do Marfim', 'alpha2': 'ci', 'alpha3': 'civ' },
  { 'id': 188, 'name': 'Costa Rica', 'alpha2': 'cr', 'alpha3': 'cri' },
  { 'id': 191, 'name': 'Croácia', 'alpha2': 'hr', 'alpha3': 'hrv' },
  { 'id': 192, 'name': 'Cuba', 'alpha2': 'cu', 'alpha3': 'cub' },
  { 'id': 208, 'name': 'Dinamarca', 'alpha2': 'dk', 'alpha3': 'dnk' },
  { 'id': 262, 'name': 'Djibuti', 'alpha2': 'dj', 'alpha3': 'dji' },
  { 'id': 212, 'name': 'Dominica', 'alpha2': 'dm', 'alpha3': 'dma' },
  { 'id': 214, 'name': 'República Dominicana', 'alpha2': 'do', 'alpha3': 'dom' },
  { 'id': 818, 'name': 'Egito', 'alpha2': 'eg', 'alpha3': 'egy' },
  { 'id': 222, 'name': 'El Salvador', 'alpha2': 'sv', 'alpha3': 'slv' },
  { 'id': 784, 'name': 'Emirados Árabes Unidos', 'alpha2': 'ae', 'alpha3': 'are' },
  { 'id': 218, 'name': 'Equador', 'alpha2': 'ec', 'alpha3': 'ecu' },
  { 'id': 232, 'name': 'Eritreia', 'alpha2': 'er', 'alpha3': 'eri' },
  { 'id': 703, 'name': 'Eslováquia', 'alpha2': 'sk', 'alpha3': 'svk' },
  { 'id': 705, 'name': 'Eslovênia', 'alpha2': 'si', 'alpha3': 'svn' },
  { 'id': 724, 'name': 'Espanha', 'alpha2': 'es', 'alpha3': 'esp' },
  { 'id': 840, 'name': 'Estados Unidos', 'alpha2': 'us', 'alpha3': 'usa' },
  { 'id': 233, 'name': 'Estónia', 'alpha2': 'ee', 'alpha3': 'est' },
  { 'id': 748, 'name': 'Essuatíni', 'alpha2': 'sz', 'alpha3': 'swz' },
  { 'id': 231, 'name': 'Etiópia', 'alpha2': 'et', 'alpha3': 'eth' },
  { 'id': 242, 'name': 'Fiji', 'alpha2': 'fj', 'alpha3': 'fji' },
  { 'id': 608, 'name': 'Filipinas', 'alpha2': 'ph', 'alpha3': 'phl' },
  { 'id': 246, 'name': 'Finlândia', 'alpha2': 'fi', 'alpha3': 'fin' },
  { 'id': 250, 'name': 'França', 'alpha2': 'fr', 'alpha3': 'fra' },
  { 'id': 266, 'name': 'Gabão', 'alpha2': 'ga', 'alpha3': 'gab' },
  { 'id': 270, 'name': 'Gâmbia', 'alpha2': 'gm', 'alpha3': 'gmb' },
  { 'id': 288, 'name': 'Gana', 'alpha2': 'gh', 'alpha3': 'gha' },
  { 'id': 268, 'name': 'Geórgia', 'alpha2': 'ge', 'alpha3': 'geo' },
  { 'id': 308, 'name': 'Granada', 'alpha2': 'gd', 'alpha3': 'grd' },
  { 'id': 300, 'name': 'Grécia', 'alpha2': 'gr', 'alpha3': 'grc' },
  { 'id': 320, 'name': 'Guatemala', 'alpha2': 'gt', 'alpha3': 'gtm' },
  { 'id': 328, 'name': 'Guiana', 'alpha2': 'gy', 'alpha3': 'guy' },
  { 'id': 624, 'name': 'Guiné-Bissau', 'alpha2': 'gw', 'alpha3': 'gnb' },
  { 'id': 324, 'name': 'Guiné', 'alpha2': 'gn', 'alpha3': 'gin' },
  { 'id': 226, 'name': 'Guiné Equatorial', 'alpha2': 'gq', 'alpha3': 'gnq' },
  { 'id': 332, 'name': 'Haiti', 'alpha2': 'ht', 'alpha3': 'hti' },
  { 'id': 340, 'name': 'Honduras', 'alpha2': 'hn', 'alpha3': 'hnd' },
  { 'id': 348, 'name': 'Hungria', 'alpha2': 'hu', 'alpha3': 'hun' },
  { 'id': 887, 'name': 'Iêmen', 'alpha2': 'ye', 'alpha3': 'yem' },
  { 'id': 356, 'name': 'Índia', 'alpha2': 'in', 'alpha3': 'ind' },
  { 'id': 360, 'name': 'Indonésia', 'alpha2': 'id', 'alpha3': 'idn' },
  { 'id': 368, 'name': 'Iraque', 'alpha2': 'iq', 'alpha3': 'irq' },
  { 'id': 364, 'name': 'Irão', 'alpha2': 'ir', 'alpha3': 'irn' },
  { 'id': 372, 'name': 'Irlanda', 'alpha2': 'ie', 'alpha3': 'irl' },
  { 'id': 352, 'name': 'Islândia', 'alpha2': 'is', 'alpha3': 'isl' },
  { 'id': 376, 'name': 'Israel', 'alpha2': 'il', 'alpha3': 'isr' },
  { 'id': 380, 'name': 'Itália', 'alpha2': 'it', 'alpha3': 'ita' },
  { 'id': 388, 'name': 'Jamaica', 'alpha2': 'jm', 'alpha3': 'jam' },
  { 'id': 392, 'name': 'Japão', 'alpha2': 'jp', 'alpha3': 'jpn' },
  { 'id': 400, 'name': 'Jordânia', 'alpha2': 'jo', 'alpha3': 'jor' },
  { 'id': 414, 'name': 'Kuwait', 'alpha2': 'kw', 'alpha3': 'kwt' },
  { 'id': 418, 'name': 'Laos', 'alpha2': 'la', 'alpha3': 'lao' },
  { 'id': 426, 'name': 'Lesoto', 'alpha2': 'ls', 'alpha3': 'lso' },
  { 'id': 428, 'name': 'Letônia', 'alpha2': 'lv', 'alpha3': 'lva' },
  { 'id': 422, 'name': 'Líbano', 'alpha2': 'lb', 'alpha3': 'lbn' },
  { 'id': 430, 'name': 'Libéria', 'alpha2': 'lr', 'alpha3': 'lbr' },
  { 'id': 434, 'name': 'Líbia', 'alpha2': 'ly', 'alpha3': 'lby' },
  { 'id': 438, 'name': 'Listenstaine', 'alpha2': 'li', 'alpha3': 'lie' },
  { 'id': 440, 'name': 'Lituânia', 'alpha2': 'lt', 'alpha3': 'ltu' },
  { 'id': 442, 'name': 'Luxemburgo', 'alpha2': 'lu', 'alpha3': 'lux' },
  { 'id': 807, 'name': 'Macedônia do Norte', 'alpha2': 'mk', 'alpha3': 'mkd' },
  { 'id': 450, 'name': 'Madagáscar', 'alpha2': 'mg', 'alpha3': 'mdg' },
  { 'id': 458, 'name': 'Malásia', 'alpha2': 'my', 'alpha3': 'mys' },
  { 'id': 454, 'name': 'Maláui', 'alpha2': 'mw', 'alpha3': 'mwi' },
  { 'id': 462, 'name': 'Maldivas', 'alpha2': 'mv', 'alpha3': 'mdv' },
  { 'id': 466, 'name': 'Mali', 'alpha2': 'ml', 'alpha3': 'mli' },
  { 'id': 470, 'name': 'Malta', 'alpha2': 'mt', 'alpha3': 'mlt' },
  { 'id': 504, 'name': 'Marrocos', 'alpha2': 'ma', 'alpha3': 'mar' },
  { 'id': 584, 'name': 'Ilhas Marshall', 'alpha2': 'mh', 'alpha3': 'mhl' },
  { 'id': 480, 'name': 'Maurícia', 'alpha2': 'mu', 'alpha3': 'mus' },
  { 'id': 478, 'name': 'Mauritânia', 'alpha2': 'mr', 'alpha3': 'mrt' },
  { 'id': 484, 'name': 'México', 'alpha2': 'mx', 'alpha3': 'mex' },
  { 'id': 104, 'name': 'Mianmar', 'alpha2': 'mm', 'alpha3': 'mmr' },
  { 'id': 583, 'name': 'Estados Federados da Micronésia', 'alpha2': 'fm', 'alpha3': 'fsm' },
  { 'id': 508, 'name': 'Moçambique', 'alpha2': 'mz', 'alpha3': 'moz' },
  { 'id': 498, 'name': 'Moldávia', 'alpha2': 'md', 'alpha3': 'mda' },
  { 'id': 492, 'name': 'Mónaco', 'alpha2': 'mc', 'alpha3': 'mco' },
  { 'id': 496, 'name': 'Mongólia', 'alpha2': 'mn', 'alpha3': 'mng' },
  { 'id': 499, 'name': 'Montenegro', 'alpha2': 'me', 'alpha3': 'mne' },
  { 'id': 516, 'name': 'Namíbia', 'alpha2': 'na', 'alpha3': 'nam' },
  { 'id': 520, 'name': 'Nauru', 'alpha2': 'nr', 'alpha3': 'nru' },
  { 'id': 524, 'name': 'Nepal', 'alpha2': 'np', 'alpha3': 'npl' },
  { 'id': 558, 'name': 'Nicarágua', 'alpha2': 'ni', 'alpha3': 'nic' },
  { 'id': 562, 'name': 'Níger', 'alpha2': 'ne', 'alpha3': 'ner' },
  { 'id': 566, 'name': 'Nigéria', 'alpha2': 'ng', 'alpha3': 'nga' },
  { 'id': 578, 'name': 'Noruega', 'alpha2': 'no', 'alpha3': 'nor' },
  { 'id': 554, 'name': 'Nova Zelândia', 'alpha2': 'nz', 'alpha3': 'nzl' },
  { 'id': 512, 'name': 'Omã', 'alpha2': 'om', 'alpha3': 'omn' },
  { 'id': 528, 'name': 'Países Baixos', 'alpha2': 'nl', 'alpha3': 'nld' },
  { 'id': 585, 'name': 'Palau', 'alpha2': 'pw', 'alpha3': 'plw' },
  { 'id': 591, 'name': 'Panamá', 'alpha2': 'pa', 'alpha3': 'pan' },
  { 'id': 598, 'name': 'Papua-Nova Guiné', 'alpha2': 'pg', 'alpha3': 'png' },
  { 'id': 586, 'name': 'Paquistão', 'alpha2': 'pk', 'alpha3': 'pak' },
  { 'id': 600, 'name': 'Paraguai', 'alpha2': 'py', 'alpha3': 'pry' },
  { 'id': 604, 'name': 'Peru', 'alpha2': 'pe', 'alpha3': 'per' },
  { 'id': 616, 'name': 'Polónia', 'alpha2': 'pl', 'alpha3': 'pol' },
  { 'id': 620, 'name': 'Portugal', 'alpha2': 'pt', 'alpha3': 'prt' },
  { 'id': 404, 'name': 'Quênia', 'alpha2': 'ke', 'alpha3': 'ken' },
  { 'id': 417, 'name': 'Quirguistão', 'alpha2': 'kg', 'alpha3': 'kgz' },
  { 'id': 296, 'name': 'Quiribáti', 'alpha2': 'ki', 'alpha3': 'kir' },
  { 'id': 826, 'name': 'Reino Unido', 'alpha2': 'gb', 'alpha3': 'gbr' },
  { 'id': 642, 'name': 'Roménia', 'alpha2': 'ro', 'alpha3': 'rou' },
  { 'id': 646, 'name': 'Ruanda', 'alpha2': 'rw', 'alpha3': 'rwa' },
  { 'id': 643, 'name': 'Rússia', 'alpha2': 'ru', 'alpha3': 'rus' },
  { 'id': 882, 'name': 'Samoa', 'alpha2': 'ws', 'alpha3': 'wsm' },
  { 'id': 90, 'name': 'Ilhas Salomão', 'alpha2': 'sb', 'alpha3': 'slb' },
  { 'id': 674, 'name': 'San Marino', 'alpha2': 'sm', 'alpha3': 'smr' },
  { 'id': 662, 'name': 'Santa Lúcia', 'alpha2': 'lc', 'alpha3': 'lca' },
  { 'id': 659, 'name': 'São Cristóvão e Neves', 'alpha2': 'kn', 'alpha3': 'kna' },
  { 'id': 678, 'name': 'São Tomé e Príncipe', 'alpha2': 'st', 'alpha3': 'stp' },
  { 'id': 670, 'name': 'São Vicente e Granadinas', 'alpha2': 'vc', 'alpha3': 'vct' },
  { 'id': 690, 'name': 'Seicheles', 'alpha2': 'sc', 'alpha3': 'syc' },
  { 'id': 686, 'name': 'Senegal', 'alpha2': 'sn', 'alpha3': 'sen' },
  { 'id': 144, 'name': 'Seri Lanca', 'alpha2': 'lk', 'alpha3': 'lka' },
  { 'id': 694, 'name': 'Serra Leoa', 'alpha2': 'sl', 'alpha3': 'sle' },
  { 'id': 688, 'name': 'Sérvia', 'alpha2': 'rs', 'alpha3': 'srb' },
  { 'id': 702, 'name': 'Singapura', 'alpha2': 'sg', 'alpha3': 'sgp' },
  { 'id': 760, 'name': 'Síria', 'alpha2': 'sy', 'alpha3': 'syr' },
  { 'id': 706, 'name': 'Somália', 'alpha2': 'so', 'alpha3': 'som' },
  { 'id': 729, 'name': 'Sudão', 'alpha2': 'sd', 'alpha3': 'sdn' },
  { 'id': 728, 'name': 'Sudão do Sul', 'alpha2': 'ss', 'alpha3': 'ssd' },
  { 'id': 752, 'name': 'Suécia', 'alpha2': 'se', 'alpha3': 'swe' },
  { 'id': 756, 'name': 'Suíça', 'alpha2': 'ch', 'alpha3': 'che' },
  { 'id': 740, 'name': 'Suriname', 'alpha2': 'sr', 'alpha3': 'sur' },
  { 'id': 764, 'name': 'Tailândia', 'alpha2': 'th', 'alpha3': 'tha' },
  { 'id': 762, 'name': 'Tajiquistão', 'alpha2': 'tj', 'alpha3': 'tjk' },
  { 'id': 834, 'name': 'Tanzânia', 'alpha2': 'tz', 'alpha3': 'tza' },
  { 'id': 626, 'name': 'Timor-Leste', 'alpha2': 'tl', 'alpha3': 'tls' },
  { 'id': 768, 'name': 'Togo', 'alpha2': 'tg', 'alpha3': 'tgo' },
  { 'id': 776, 'name': 'Tonga', 'alpha2': 'to', 'alpha3': 'ton' },
  { 'id': 780, 'name': 'Trinidad e Tobago', 'alpha2': 'tt', 'alpha3': 'tto' },
  { 'id': 788, 'name': 'Tunísia', 'alpha2': 'tn', 'alpha3': 'tun' },
  { 'id': 795, 'name': 'Turcomenistão', 'alpha2': 'tm', 'alpha3': 'tkm' },
  { 'id': 792, 'name': 'Turquia', 'alpha2': 'tr', 'alpha3': 'tur' },
  { 'id': 798, 'name': 'Tuvalu', 'alpha2': 'tv', 'alpha3': 'tuv' },
  { 'id': 804, 'name': 'Ucrânia', 'alpha2': 'ua', 'alpha3': 'ukr' },
  { 'id': 800, 'name': 'Uganda', 'alpha2': 'ug', 'alpha3': 'uga' },
  { 'id': 858, 'name': 'Uruguai', 'alpha2': 'uy', 'alpha3': 'ury' },
  { 'id': 860, 'name': 'Uzbequistão', 'alpha2': 'uz', 'alpha3': 'uzb' },
  { 'id': 548, 'name': 'Vanuatu', 'alpha2': 'vu', 'alpha3': 'vut' },
  { 'id': 862, 'name': 'Venezuela', 'alpha2': 've', 'alpha3': 'ven' },
  { 'id': 704, 'name': 'Vietnã', 'alpha2': 'vn', 'alpha3': 'vnm' },
  { 'id': 894, 'name': 'Zâmbia', 'alpha2': 'zm', 'alpha3': 'zmb' },
  { 'id': 716, 'name': 'Zimbábue', 'alpha2': 'zw', 'alpha3': 'zwe' }]
