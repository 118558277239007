import DataTableContainer from '../../generic/dataTable/DataTableContainer'
import {GridColDef} from '@mui/x-data-grid-pro'
import AddEditCountryButton from './AddEditCountryButton'
import {CountryListQuery, useGenericQuery} from '@feg/ui'
import DeleteTableRowButton from "@feg/ui/src/common/form/DeleteTableRowButton";
import {useCountryListQuery, useDeleteCountryMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {useState} from "react";

const GridColums: GridColDef<CountryListQuery['country'][number]>[] = [{
    field: 'key',
    headerName: 'Country Code',
    flex: 1
}, {
    field: 'en',
    headerName: 'English',
    flex: 1
}, {
    field: 'fr',
    headerName: 'French',
    flex: 1
}, {
    field: 'es',
    headerName: 'Spanish',
    flex: 1
}, {
    field: 'pt',
    headerName: 'Portuguese',
    flex: 1
}]

export default function CountryList() {
    const {data, isFetching, refetch} = useCountryListQuery()
    const [selectedRows, setSelectedRows] = useState<CountryListQuery['country']>([])
    useGenericQuery({
        isFetching,
        refetch,
        setSelectedRows
    })
    const {isPending, mutateAsync} = useDeleteCountryMutation()
    const rows = data?.country || []
    return (
        <DataTableContainer ToolbarElements={
            <>
                {selectedRows?.length === 1 && (
                    <AddEditCountryButton country={selectedRows[0]}/>
                )}
                {selectedRows?.length > 0 && (
                    <DeleteTableRowButton
                        refetch={refetch}
                        mutateAsync={() => mutateAsync({
                            ids: selectedRows?.map(i => i.id)
                        })}
                        count={selectedRows?.length}
                        isLoading={isPending}/>
                )}
            </>
        } dataTableProps={{
            loading: isFetching,
            columns: GridColums,
            rows,
            checkboxSelection: true,
            rowSelectionModel: selectedRows?.map(i => i.id),
            onRowSelectionModelChange: (selectionModel) => {
                const modelValues = rows.filter(i => selectionModel.includes(i.id))
                setSelectedRows(modelValues)
            }
        }}>
        </DataTableContainer>
    )
}
