import {useForm} from 'react-hook-form'
import {Alert, Box, BoxProps, Stack, Typography} from '@mui/material'
import {PasswordElement} from 'react-hook-form-mui'
import {LoadingButton} from '@mui/lab'
import {useTranslation} from 'react-i18next'
import {useChangePassword} from '@nhost/react'
import {m} from 'framer-motion'
import {varBounce} from "../../theme/components/animate/variants";
import MuiLink from "@mui/material/Link";
import {ForwardRefExoticComponent} from "react";

const schema = {
    password: '',
    username: ''
}

type User = typeof schema

type ChangePasswordFormProps = {
    onSuccess?: () => Promise<void>
    containerProps?: BoxProps
    LinkComponent?: ForwardRefExoticComponent<any>
    linkHrefTo?: boolean
}

export function ChangePasswordForm({onSuccess, containerProps, LinkComponent, linkHrefTo}: ChangePasswordFormProps) {
    const {t} = useTranslation()
    const {changePassword, error, isSuccess} = useChangePassword()
    const {control, handleSubmit} = useForm<User>({
        defaultValues: {...schema}
    })
    const onSubmit = handleSubmit(async (data) => {
        await changePassword(data.password)
        if (typeof onSuccess === 'function') {
            await onSuccess()
        }
    })

    return (
        <Box {...containerProps}>
            <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                    {t('auth.headlineChangePassword')}
                </Typography>
            </m.div>
            {isSuccess && (
                <m.div variants={varBounce().in}>
                    <Alert severity={'success'}>
                        {t('auth.newPasswordResetMessage')}
                    </Alert>
                </m.div>
            )}
            {error && (
                <m.div variants={varBounce().in}>
                    <Alert severity={'error'}>
                        {error?.message}
                    </Alert>
                </m.div>
            )}
            {!isSuccess && (
                <form onSubmit={onSubmit} noValidate>
                    <Stack direction={'column'} spacing={2}>
                        <PasswordElement name={'password'} label={t('auth.newPassword')} control={control}/>
                        <LoadingButton type={'submit'}>{t('submit')}</LoadingButton>
                    </Stack>
                </form>
            )}
            <Stack mt={3}>
                <MuiLink
                    {...LinkComponent && {
                        component: LinkComponent
                    }}
                    {...linkHrefTo ? {
                        to: '/auth/forgot-password'
                    } : {
                        href: '/auth/forgot-password'
                    }}
                >
                    {t('auth.backToLogin')}
                </MuiLink>
            </Stack>
        </Box>
    )
}
