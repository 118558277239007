import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {PropsWithChildren} from "react";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

const tanstackQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            // cacheTime: 1000 * 60 * 60 * 24, // 24 hours,
            // staleTime: 1000 * 60 * 60 * 2, // 1 hour
        }
    }
})

export function NhostSimpleReactQueryProvider({children}: PropsWithChildren) {
    return (
        <QueryClientProvider client={tanstackQueryClient}>
            {children}
            <ReactQueryDevtools
                buttonPosition={'bottom-left'}
                initialIsOpen={false}/>
        </QueryClientProvider>
    )
}