import {TextFieldElement} from 'react-hook-form-mui'

import {useTranslation} from 'react-i18next'
import {
    CountryListQuery,
    CountryRegionQuery,
    UpsertCountryRegionMutationVariables,
    useGenericMutation,
    useMutationResult
} from '@feg/ui'
import SubmitButton from '../../generic/buttons/SubmitButton'
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {FormErrorMessage} from "@feg/ui/src/common/form/FormErrorMessage";
import {
    useCountryListQuery,
    useCountryRegionQuery,
    useUpsertCountryRegionMutation
} from "@feg/ui/src/lib/gql/__codegen__/react-query";
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";

const schema = {
    name: '',
    country_id: '',
    parent_id: ''
}
type Schema = typeof schema

type CountryFormProps = {
    region?: CountryRegionQuery['country_region'][number]
}

export default function CountryRegionForm({region}: CountryFormProps) {
    const {t} = useTranslation()
    const {mutateAsync, data, error, isPending} = useUpsertCountryRegionMutation()
    const {
        control,
        onSubmit,
        watch
    } = useGenericMutation<Schema, UpsertCountryRegionMutationVariables, typeof mutateAsync>({
        id: region?.id,
        defaultValues: {
            name: region?.name,
            country_id: region?.country_id || null,
            parent_id: region?.parent_id || null
        },
        mutateAsync,
        gqlSchemaName: 'insert_country_region',
        prepareFormData: data => {
            const splittedNames = data.name.split(',').map(k => k.trim())
            return {
                objects: splittedNames.map(name => ({
                    id: region?.id,
                    name,
                    country_id: data.country_id,
                    parent_id: data.parent_id || null
                }))
            }
        }
    })
    useMutationResult({data, error})
    const selectedCountryId = watch('country_id')
    return (
        <form noValidate onSubmit={onSubmit}>
            <FormStack>
                <FormErrorMessage control={control}/>

                <AutocompleteFetchQuery
                    queryKey={useCountryListQuery.getKey()}
                    asyncQuery={useCountryListQuery.fetcher()}
                    name={'country_id'}
                    label={t('country.name')}
                    matchId
                    gqlSchema={'country'}
                    autocompleteProps={{
                        getOptionLabel: (option: CountryListQuery['country'][number] | string) => {
                            if (typeof option === 'string') return option
                            if (!option) return ''
                            return option.en
                        }
                    }}
                    control={control}/>
                <TextFieldElement name={'name'}
                                  label={t('countryRegion.form.name')}
                                  control={control}
                                  {...!region?.id && {
                                      multiline: true,
                                      minRows: 3,
                                      helperText: t('countryRegion.form.nameHelper')
                                  }}
                                  disabled={!selectedCountryId}/>
                <AutocompleteFetchQuery
                    name={'parent_id'}
                    label={t('countryRegion.form.higherDistrictName')}
                    queryKey={useCountryRegionQuery.getKey({
                        where: {
                            country_id: {
                                _eq: selectedCountryId
                            }
                        }
                    })}
                    asyncQuery={useCountryRegionQuery.fetcher({
                        where: {
                            country_id: {
                                _eq: selectedCountryId
                            }
                        }
                    })}
                    gqlSchema={'country_region'}
                    control={control}
                    autocompleteProps={{
                        disabled: !selectedCountryId,
                        getOptionLabel: (option: CountryRegionQuery['country_region'][number] | string) => {
                            if (typeof option === 'string') return option
                            if (!option) return ''
                            return option.name
                        }
                    }}
                />

                <SubmitButton loading={isPending}/>
            </FormStack>
        </form>
    )
}
