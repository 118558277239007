import {PATH_DASHBOARD} from '@feg/ui'
import UsersList from '../../../components/users/UsersList'
import {useTranslation} from 'react-i18next'
import AddEditUserButton from '../../../components/users/AddEditUserButton'
import {PageRoot} from "@feg/ui/src/common/layout/PageRoot";


export default function UserListPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('user.list.main')}
                  breadcrumbs={{
                      heading: t('user.list.main'),
                      links: [
                          {name: t('dashboard.main'), href: PATH_DASHBOARD.general.app},
                          {name: t('user.list.main'), href: PATH_DASHBOARD.user.list}
                      ],
                      action: (
                          <AddEditUserButton/>
                      )
                  }}>
            <UsersList/>
        </PageRoot>
    )
}

