import {useEffect} from 'react'
import {useRefetchState} from '../lib'
import {useSnackbar} from 'notistack'
import {useShallow} from "zustand/react/shallow";

export function useMutationResult({
                                      error,
                                      data
                                  }: { error: any, data: any }) {
    const setRefetchTimestamp = useRefetchState(useShallow(state => state.setRefetchTimestamp))
    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {
            if (error) {
                // do some notistack
                console.error(error.message)
                enqueueSnackbar('Error on data mutation', {variant: 'error'})
            }
        },
        [enqueueSnackbar, error]
    )
    useEffect(() => {
        if (data) {
            setRefetchTimestamp()
        } else if (data === null) {
            // update is not permitted.
            enqueueSnackbar('Error on data mutation', {variant: 'error'})
        }
    }, [data, enqueueSnackbar, setRefetchTimestamp])
}
