import {PasswordForgetForm} from "../../common/auth/PasswordForgetForm";
import {Link} from "react-router-dom";
import {PageRootSimpleSpa} from "../../common/layout/PageRootSimpleSpa";

export default function ForgotPassword() {
    return (
        <PageRootSimpleSpa title={'Login'}>
            <PasswordForgetForm LinkComponent={Link} linkHrefTo/>
        </PageRootSimpleSpa>
    )
}