import {
    keysOfObject,
    ProjectByPkQuery,
    ProjectScalarFragment,
    TermListQuery,
    UpdateProjectMutationVariables,
    useGenericMutation,
    useMutationResult,
    UsersQuery
} from '@feg/ui'
import {Alert, Card, CardContent, Divider, FormControlLabel, FormGroup, Grid, Stack, Switch} from '@mui/material'
import pick from 'lodash/pick'
import {useState} from 'react'
import {DatePickerElement, SwitchElement, TextFieldElement} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import SubmitButton from '../generic/buttons/SubmitButton'
import CurrencySelector from './partials/CurrencySelector'
import PasswordChange from './partials/PasswordChange'
import ProjectInterviewList from "./partials/ProjectInterviewList"
import ToggleProjectDeleteStatus from './partials/ToggleProjectDeleteStatus'
import {LmTipTapRichtextRhf} from "@feg/ui/src/common/form/richText/TipTapRhf";
import {TextFieldNumberFormat} from "@feg/ui/src/common/form/TextFieldNumberFormat";
import {FormStack} from "@feg/ui/src/common/form/FormStack";
import {FormErrorMessage} from "@feg/ui/src/common/form/FormErrorMessage";
import {AutocompleteFetchQuery} from "@feg/ui/src/common/form/AutocompleteGqlQuery";
import {useTermListQuery, useUpdateProjectMutation, useUsersQuery} from "@feg/ui/src/lib/gql/__codegen__/react-query";


const schema: ProjectScalarFragment = {
    name: '',
    supervisor_user_id: '',
    teamleader_user_id: '',
    main_staple_id: '',
    reference_period_start: '',
    reference_period_end: '',
    reference_period_duration: 0,
    kcal_pppd: '',
    currency: '',
    reference_period_description: '',
    currency_exchange_usd: '',
    avg_price_main_staple_kg: '',
    school_food_kcal: '',
    schooldays_year: 0,
    finished: false,
    password: ''
}

type Schema = typeof schema


type FormData = NonNullable<ProjectByPkQuery['project_by_pk']>
type ProjectEditProps = {
    project: FormData
}

export default function ProjectEdit({project}: ProjectEditProps) {
    const {t} = useTranslation()
    const {error, data, isPending, mutateAsync} = useUpdateProjectMutation()
    const {
        control,
        onSubmit,
        setValue
    } = useGenericMutation<Schema, UpdateProjectMutationVariables, typeof mutateAsync>({
        id: project?.id,
        defaultValues: {
            ...pick(project, keysOfObject(schema)),
            // reference_period_start: new Date(project.reference_period_start),
            finished: project.finished || false // doesnt handle bool
        },
        mutateAsync,
        gqlSchemaName: 'update_project_by_pk',
        // struct: schema,
        prepareFormData: (formData) => {
            return {
                id: project.id,
                set: {
                    ...formData
                }
            }
        }
    })
    useMutationResult({error, data})
    const hasAlreadyInterviewBaseData = !!project.project_interviews?.find(item => item.project_interview_base?.id)
    const [lockedInterviewState, setLockedState] = useState(hasAlreadyInterviewBaseData)


    return (
        <form noValidate onSubmit={onSubmit}>
            <Grid container spacing={3}>
                <Grid item xs sm={7}>
                    <Card>
                        <CardContent>
                            <FormStack>
                                <FormErrorMessage control={control}/>
                                <TextFieldElement fullWidth name={'name'} control={control}
                                                  label={t('project.form.name')}/>
                                <AutocompleteFetchQuery
                                    name={'supervisor_user_id'}
                                    label={t('project.form.supervisor')}
                                    control={control}
                                    queryKey={useUsersQuery.getKey()}
                                    asyncQuery={useUsersQuery.fetcher()}
                                    gqlSchema={'users'}
                                    matchId={true}
                                    autocompleteProps={{
                                        getOptionLabel: (option: UsersQuery['users'][number] | string) => {
                                            if (typeof option === 'string') {
                                                return option
                                            }
                                            if (!option.user_profile) {
                                                return ''
                                            }
                                            return `${option.user_profile?.first_name} ${option.user_profile?.last_name} (${option.email})`
                                        }
                                    }}
                                />
                                <AutocompleteFetchQuery
                                    name={'teamleader_user_id'}
                                    matchId={true}
                                    label={t('project.form.teamleader')}
                                    control={control}
                                    queryKey={useUsersQuery.getKey()}
                                    asyncQuery={useUsersQuery.fetcher()}
                                    gqlSchema={'users'}
                                    autocompleteProps={{
                                        getOptionLabel: (option: UsersQuery['users'][number] | string) => {
                                            if (typeof option === 'string') {
                                                return option
                                            }
                                            if (!option.user_profile) {
                                                return ''
                                            }
                                            return `${option.user_profile?.first_name} ${option.user_profile?.last_name} (${option.email})`
                                        }
                                    }}
                                />
                                <LmTipTapRichtextRhf name={'reference_period_description'}
                                                     control={control}/>
                            </FormStack>


                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs sm={5}>
                    <Card>
                        <CardContent>
                            <FormStack>
                                <Stack direction={'row'} gap={2}>
                                    <DatePickerElement name={'reference_period_start'}
                                                       sx={{flex: 1}}
                                                       control={control}
                                                       label={t('project.form.referenceStart')}/>
                                    <DatePickerElement name={'reference_period_end'} control={control}
                                                       sx={{flex: 1}}
                                                       label={t('project.form.referenceEnd')}/>
                                </Stack>
                                <TextFieldNumberFormat name={'reference_period_duration'}
                                                       control={control as any}
                                                       disabled={lockedInterviewState}
                                                       label={t('project.form.referenceDuration')}
                                />
                                <TextFieldNumberFormat name={'kcal_pppd'} control={control as any}
                                                       disabled={lockedInterviewState}
                                                       label={t('project.form.kcalPppd')}/>
                                <Stack direction={'row'} gap={2}>
                                    <CurrencySelector control={control}
                                                      name={'currency'}
                                                      autocompleteProps={{
                                                          fullWidth: true,
                                                          //@ts-ignore
                                                          disabled: lockedInterviewState
                                                      }}/>
                                    <TextFieldNumberFormat name={'currency_exchange_usd'} fullWidth
                                                           control={control as any}
                                                           disabled={lockedInterviewState}
                                                           label={t('project.form.currencyExchangeUsd')}/>
                                </Stack>
                                <Stack direction={'row'} gap={2}>
                                    <TextFieldNumberFormat name={'schooldays_year'}
                                                           disabled={lockedInterviewState}
                                                           fullWidth control={control as any}
                                                           label={t('project.form.schoolDays')}/>
                                    <TextFieldNumberFormat name={'school_food_kcal'} disabled={lockedInterviewState}
                                                           fullWidth control={control as any}
                                                           label={t('project.form.schoolKcal')}/>
                                </Stack>
                                <TextFieldNumberFormat name={'avg_price_main_staple_kg'} fullWidth
                                                       control={control as any}
                                                       disabled={lockedInterviewState}
                                                       label={t('project.form.avgPriceMainStaple')}/>
                                <AutocompleteFetchQuery
                                    name={'main_staple_id'}
                                    matchId={true}
                                    label={t('project.form.mainStaple')}
                                    control={control}
                                    queryKey={useTermListQuery.getKey()}
                                    asyncQuery={useTermListQuery.fetcher({
                                        where: {
                                            term_category_mns: {
                                                term_category: {
                                                    id: {
                                                        _in: ['f50792d4-25cd-4bbd-b7a4-19f096957299', '3222baf3-8f4f-421f-a200-eced33c72312']
                                                    }
                                                }
                                            }
                                        }
                                    })}
                                    gqlSchema={'term'}
                                    autocompleteProps={{
                                        getOptionLabel: (option: TermListQuery['term'][number] | string) => {
                                            if (typeof option === 'string') {
                                                return option
                                            }
                                            return option.en
                                        },
                                        //@ts-ignore
                                        disabled: lockedInterviewState
                                    }}
                                />

                                {hasAlreadyInterviewBaseData && (
                                    <Alert severity={'warning'}>
                                        <FormStack>
                                            {t('projectLockedDueUploadedInterview')}
                                            <FormGroup onChange={() => setLockedState(state => !state)}
                                                       sx={{marginLeft: 1}}>
                                                <FormControlLabel control={<Switch value={!lockedInterviewState}/>}
                                                                  label={t('enableEdit')}/>
                                            </FormGroup>
                                        </FormStack>

                                    </Alert>
                                )}
                            </FormStack>
                        </CardContent>
                    </Card>
                    <SubmitButton loading={isPending} size={'large'} fullWidth variant={'contained'} sx={{
                        mt: 3
                    }}/>
                    <Stack justifyContent={'space-between'} direction={'row'}>
                        <PasswordChange name={'password'} control={control} setValue={setValue}/>
                        <SwitchElement name={'finished'}
                                       control={control}
                                       label={t('project.detail.finished') as string}
                                       labelPlacement={'start'}
                                       sx={{justifyContent: 'space-between'}}/>
                    </Stack>
                    {project?.id && (
                        <Stack mt={1} direction={'row'} justifyContent={'flex-end'}>
                            <ToggleProjectDeleteStatus id={project.id} isDeleted={!!project.deleted}/>
                        </Stack>
                    )}
                </Grid>
                <Grid xs={12} item>
                    <Divider sx={{my: 3}}/>
                    <Card>
                        <ProjectInterviewList interviews={project.project_interviews} projectId={project.id}/>
                    </Card>

                </Grid>
            </Grid>
        </form>
    )
}
