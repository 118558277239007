import SidebarDrawer from '../../generic/drawers/SidebarDrawer'
import { useTranslation } from 'react-i18next'
import TermForm from './TermForm'
import { TermListQuery } from '@feg/ui'

type AddEditTermButtonProps = {
  term?: TermListQuery['term'][number]
}
export default function AddEditTermButton({ term }: AddEditTermButtonProps) {
  const { t } = useTranslation()
  return (
    <SidebarDrawer
      closeOnSubmit
      addButton={!term ? t('term.list.add') : undefined}
      editButton={term ? t('term.list.edit') : undefined}
      headline={term ? t('term.list.edit') : t('term.list.add')}>
      <TermForm term={term} />
    </SidebarDrawer>
  )
}
