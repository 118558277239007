import {useTranslation} from 'react-i18next'
import {useMutationResult} from '@feg/ui'
import {useState} from 'react'
import {ConfirmButton} from "@feg/ui/src/common/buttons";
import {useUpdateProjectMutation} from "@feg/ui/src/lib/gql/__codegen__/react-query";

type ToggleProjectDeleteStatusProps = {
    id: string
    isDeleted?: boolean
}

export default function ToggleProjectDeleteStatus({id, isDeleted}: ToggleProjectDeleteStatusProps) {
    const {t} = useTranslation()
    const [currentStatus, setStatus] = useState<boolean | undefined>(isDeleted)
    const {isPending, mutateAsync, error, data} = useUpdateProjectMutation()
    useMutationResult({error, data})
    return (
        <ConfirmButton
            buttonProps={{
                color: currentStatus ? 'warning' : 'error',
                loading: isPending
            }}
            message={currentStatus ? t('recoverData') : t('deleteMessage')}
            onConfirm={async () => {
                await mutateAsync({
                    id,
                    set: {
                        deleted: !currentStatus
                    }
                })
                setStatus(!currentStatus)
            }}>
            {currentStatus ? t('recoverData') : t('delete')}
        </ConfirmButton>
    )
}
