import {useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuControlsContainer,
    RichTextEditorProvider,
    RichTextField,
} from "mui-tiptap";
import {LmTiptapRichtextProps} from "./richtTextTypes";


export function LmTiptapRichtext({
                                     value = '',
                                     onChange,
                                     options,
                                     placeholder = 'Start typing...',
                                 }: LmTiptapRichtextProps) {
    const editor = useEditor({
        ...options,
        extensions: [StarterKit],
        content: value,
        onUpdate: ({editor}) => {
            onChange?.(editor.getHTML())
        },
    });
    return (
        <RichTextEditorProvider editor={editor}>
            <RichTextField
                controls={
                    <MenuControlsContainer>
                        <MenuButtonBold/>
                        <MenuButtonItalic/>
                        <MenuButtonBulletedList/>
                        <MenuButtonOrderedList/>
                        {/* Add more controls of your choosing here */}
                    </MenuControlsContainer>
                }
            />
        </RichTextEditorProvider>
    );
}
