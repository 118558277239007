import {Alert} from '@mui/material'
import {Control, FieldValues, useFormState} from 'react-hook-form'

type FormErrorMessageProps<T extends FieldValues> = {
    control: Control<T>
}

export function FormErrorMessage<TFieldValues extends FieldValues>({control}: FormErrorMessageProps<TFieldValues>) {
    const {errors} = useFormState({control})
    const errorMessages = Object.keys(errors).map(key => {
        // @ts-ignore
        return `<p>Field "${key}" fails with: ${errors[key].message}</p>`
    })
    if (errorMessages.length) {
        return (
            <Alert severity={'error'}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: errorMessages.join('')
                    }}/>
            </Alert>
        )
    }
    return null
}
