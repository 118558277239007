import {NhostClient} from '@nhost/react'
import {CONFIG} from '../config'
import {Variables} from "graphql-request";
import {DocumentNode} from 'graphql'

export const appNhostClient: NhostClient = new NhostClient({
    ...CONFIG.nhost
})

export async function getNhostGqlRequest<T, V>(doc: DocumentNode, props: V) {
    return appNhostClient.graphql.request<T>(doc, props as Variables)
}
